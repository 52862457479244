import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import api_url from "../../ApiUrl";
import { ToastContainer, toast } from "react-toastify";
import TopNav from "../TopNav";
import BottomNav from "../BottomNav";

import Awami from "../../makkaanpages/images/Awami-block-1.webp";

import Relatedproject from "./Relatedproject";

const ProjectViews = () => {
  const { id } = useParams();
  console.log(id);
  const [myPost, setMyPost] = useState({});
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(api_url + `property/get/${id}`)
      .then((res) => {
        console.log("API Response:", res.data); // Check the full API response
        if (res.data && res.data.data) {
          setMyPost(res.data.data);
          console.log(myPost);
        } else {
          setMyPost({}); // Set to an empty object if data is not as expected
          toast.error("No data found for this property.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setMyPost({}); // Ensure myPost is set even on error
        toast.error("Error fetching property details.");
      });
  }, [id]);

  const submitForm = () => {
    const name = document.getElementById("name").value;
    const email = document.getElementById("sendemail").value;
    const phone = document.getElementById("phone").value;
    const message = document.getElementById("message").value;

    if (!name) {
      toast.error("Must enter name");
    } else if (!email) {
      toast.error("Must enter email");
    } else if (!phone) {
      toast.error("Must enter phone");
    } else if (!message) {
      toast.error("Must enter message");
    } else {
      const params = {
        name,
        email,
        phone,
        message,
        propertyId: id,
        propertymail: myPost.users
          ? myPost.users.email
          : "newsletter@makkaan.com",
      };

      axios
        .post(`${api_url}enquiry/create`, params)
        .then((res) => {
          if (res.data.status === "ok") {
            toast.success("Enquiry created successfully");
          } else {
            toast.error("Error creating enquiry");
          }
        })
        .catch((error) => {
          console.error("Error posting enquiry: ", error);
          toast.error("Failed to create enquiry.");
        });
    }
  };

  // Assuming `myPost.feature` is a string of features separated by commas
  const feature = myPost && myPost.feature ? myPost.feature.split(",") : [];

  return (
    <>
      <div className="position-fixed w-100" style={{ zIndex: "999999" }}>
        <TopNav />
        <BottomNav />
      </div>
      <div
        class="container-fluid"
        style={{ paddingTop: "10rem", paddingBottom: "3rem" }}
      >
        <div class="row">
          <div class="col-sm-8">
            <img src={Awami} style={{ width: "100%", height: "auto" }} />
          </div>
          <div class="col-sm-4">
            {" "}
            <img src={Awami} style={{ width: "100%", height: "auto" }} />
            <img
              src={Awami}
              style={{ width: "100%", height: "auto", paddingTop: "0.89em" }}
            />
          </div>
        </div>
      </div>

      <div className="container" style={{ paddingBottom: "10rem" }}>
        <div className="row">
          <div className="col-sm-8">
            <h3>Title{myPost?.title}</h3>
            <div className="col-10">
              <div className="row">
                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("./../../img/location.png")}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />

                    <div class="ms-3">
                      <p class="fw-bold mb-1">Askari 14</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("./../../img/city.png")}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />

                    <div class="ms-3">
                      <p class="fw-bold mb-1">Rawalpindi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-2">
                <h3 style={{ color: "#4db749" }}>Overview</h3>
              </div>
              <div className="col-10">
                <div className="read-more-container">
                  <p className="read-more-text">
                    This attractive villa is on an elevated plot of around
                    1.166m2 and is offered for sale in the prestigious
                    residential area called Alcanada in Alcudia. Just a stone´s
                    throw from the golf course and nearby restaurants, this
                    impressive villa also boasts breath-taking views of the sea
                    and mountains. The villa has a total of five bedrooms and
                    five full bathrooms which are distributed across two storeys
                    plus a basement level. On the ground floor you will find a
                    grand entrance hall with staircase, a large kitchen-diner
                    with a pantry and access to the barbecue area, a separate
                    dining area, and a split level living room with frameless
                    windows which follow the curve of the building and grant
                    stunning views including that of the renowned lighthouse,
                    located on an island beside the golf course. This level also
                    includes a laundry room, a bathroom, and a double bedroom
                    which is currently used as an office.
                    {isReadMore && (
                      <span className="more-text">
                        When the user clicks on "Read More", the rest of the
                        text will be displayed, giving them additional
                        information. This is useful for keeping initial content
                        concise while still providing full details on demand.
                      </span>
                    )}
                  </p>
                  <span onClick={toggleReadMore} className="read-more-btn">
                    {isReadMore ? "Read Less" : "Read More"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-2">
                <h3 style={{ color: "#4db749" }}>Features</h3>
              </div>
              <div className="col-10">
                <div className="read-more-container">
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div className="d-flex align-items-center">
                        <img
                          src={require("./../../img/view.png")}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />

                        <div class="ms-3">
                          <p class="fw-bold mb-1">Mountain view</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div class="d-flex align-items-center">
                        <img
                          src={require("./../../img/park.png")}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">Garden</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div class="d-flex align-items-center">
                        <img
                          src={require("./../../img/terrace.png")}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">Terrace</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div class="d-flex align-items-center">
                        <img
                          src={require("./../../img/steam-room.png")}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">Steam Room</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div class="d-flex align-items-center">
                        <img
                          src={require("./../../img/kitchen.png")}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">Open Kitchen</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 mb-4 mt-3">
                      <div class="d-flex align-items-center">
                        <img
                          src={require("./../../img/fire-extinguisher.png")}
                          alt=""
                          style={{ width: "30px", height: "30px" }}
                        />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">Fireplace</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div className="d-flex align-items-center">
                        <img
                          src={require("./../../img/car-parking.png")}
                          alt=""
                          style={{ width: "35px", height: "35px" }}
                        />

                        <div class="ms-3">
                          <p class="fw-bold mb-1">Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 mb-5 mt-4">
                      <div className="d-flex align-items-center">
                        <img
                          src={require("./../../img/balcony.png")}
                          alt=""
                          style={{ width: "35px", height: "35px" }}
                        />

                        <div class="ms-3">
                          <p class="fw-bold mb-1">Balcony</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                      <div className="d-flex align-items-center">
                        <img
                          src={require("./../../img/pool.png")}
                          alt=""
                          style={{ width: "35px", height: "35px" }}
                        />

                        <div class="ms-3">
                          <p class="fw-bold mb-1">Pool</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-2">
                <h3 style={{ color: "#4db749" }}>Videos</h3>
              </div>
              <div className="col-10">
                <div className="read-more-container">
                  <div
                    className="embed-responsive embed-responsive-16by9"
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                      allowFullScreen
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-2">
                <h3 style={{ color: "#4db749" }}>Location</h3>
              </div>
              <div className="col-10">
                <div className="read-more-container">
                  <div
                    className="embed-responsive embed-responsive-16by9"
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26603.453634048386!2d73.0686577696321!3d33.54215821409337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9387687d1483%3A0x27e5b67977d88568!2sMakkaan.com!5e0!3m2!1sen!2s!4v1716529700872!5m2!1sen!2s"
                      width="800"
                      height="600"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4 ">
            <div class="card p-3">
              <div class="card-header">
                <h5>PKR: 25000 {myPost?.price}</h5>
              </div>
              <br />
              <ul class="list-group list-group-light mb-4">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <img
                      src={require("./../../img/Group 23.png")}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    />
                    <div class="ms-3">
                      <p class=" mb-1">8789789789798{myPost?.phone}</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <img
                      src={require("./../../img/Group 23 (1).png")}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    />
                    <div class="ms-3">
                      <p class=" mb-1">{myPost?.phone}</p>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="row">
                <div class="col-d-12 mt-3">
                  <div data-mdb-input-init class="form-outline">
                    <input
                      type="text"
                      id="form8Example1"
                      class="form-control border-bottom"
                    />
                    <label class="form-label" for="form8Example1">
                      Your Name
                    </label>
                  </div>
                </div>
                <div class="col-d-12 mt-3">
                  <div data-mdb-input-init class="form-outline">
                    <input
                      type="email"
                      id="form8Example2"
                      class="form-control border-bottom"
                    />
                    <label class="form-label" for="form8Example2">
                      Phone Number
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-d-12 mt-3">
                  <div data-mdb-input-init class="form-outline">
                    <input
                      type="text"
                      id="form8Example3"
                      class="form-control border-bottom"
                    />
                    <label class="form-label" for="form8Example3">
                      Email Address
                    </label>
                  </div>
                </div>
                <div class="col-d-12 mt-3">
                  <div data-mdb-input-init class="form-outline">
                    <input
                      type="text"
                      id="form8Example4"
                      class="form-control border-bottom"
                    />
                    <label class="form-label" for="form8Example4">
                      Subject
                    </label>
                  </div>
                </div>
                <div class="col-d-12 mt-3">
                  <div data-mdb-input-init class="form-outline">
                    <input
                      type="email"
                      id="form8Example5"
                      class="form-control border-bottom"
                    />
                    <label class="form-label" for="form8Example5">
                      Your Message
                    </label>
                  </div>
                </div>
              </div>
              <a
                href=""
                class="btn bg_makkan text-white w-100 mt-3"
                style={{ backgroundColor: "#4DB749" }}
              >
                SEND YOUR QUESTION
              </a>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="container">
        <Relatedproject />
      </div>
      <br />
    </>
  );
};

export default ProjectViews;
