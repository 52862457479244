import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";

import Video from "./Video";
import "./homeslider.css";
const priceOptions = [
  0,
  5000,
  10000,
  20000,
  30000,
  40000,
  50000,
  75000,
  100000,
  150000,
  200000,
  300000,
  400000,
  500000,
  750000,
  1000000,
  1500000,
  2000000,
  3000000,
  4000000,
  5000000,
  10000000,
  20000000,
  30000000,
  40000000,
  50000000,
  "Any",
];

const areaOptions = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  15,
  20,
  50,
  100,
  200,
  500,
  "Any",
];

const unitOptions = [
  { label: "Marla", value: "marla" },
  { label: "Kanal", value: "kanal" },
];

const HomeSlider = () => {
  const navigate = useNavigate();

  const [pricePop, setPricePop] = useState(false);
  const [bedPop, setBedPop] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);

  const [minPrice, setMinPrice] = useState(
    localStorage.getItem("searchminprice") || 0
  );
  const [maxPrice, setMaxPrice] = useState(
    localStorage.getItem("searchmaxprice") || "Any"
  );

  const [minArea, setMinArea] = useState(
    localStorage.getItem("searchminarea") || 0
  );
  const [maxArea, setMaxArea] = useState(
    localStorage.getItem("searchmaxarea") || "Any"
  );
  const [areaUnit, setAreaUnit] = useState(
    localStorage.getItem("searchareaunit") || "Marla"
  );

  useEffect(() => {
    localStorage.setItem("searchminarea", minArea);
  }, [minArea]);

  useEffect(() => {
    localStorage.setItem("searchmaxarea", maxArea);
  }, [maxArea]);

  useEffect(() => {
    localStorage.setItem("searchminprice", minPrice);
  }, [minPrice]);

  useEffect(() => {
    localStorage.setItem("searchmaxprice", maxPrice);
  }, [maxPrice]);

  useEffect(() => {
    const savedOption = localStorage.getItem("mysetprop");
    if (savedOption) {
      setSelectedOption(savedOption);
    }
  }, []);

  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("mysetprop") || "sell"
  );

  const handleClick = (option) => {
    localStorage.setItem("mysetprop", option);
    setSelectedOption(option);
  };

  // const submitFilter = () => {
  //   const getValue = (id) => {
  //     const element = document.getElementById(id);
  //     return element ? element.value : null;
  //   };
  //   const city = getValue("city") || null;
  //   const type = getValue("type") || null;
  //   const title = getValue("title")?.trim() || null; // Use optional chaining here
  //   const beds = getValue("beds") || null;
  //   const minPrice = getValue("minPrice")
  //     ? parseFloat(getValue("minPrice"))
  //     : null;
  //   const maxPrice = getValue("maxPrice")
  //     ? parseFloat(getValue("maxPrice"))
  //     : null;
  //   const minArea = getValue("minArea")
  //     ? parseFloat(getValue("minArea"))
  //     : null;
  //   const maxArea = getValue("maxArea")
  //     ? parseFloat(getValue("maxArea"))
  //     : null;

  //   const param = {
  //     city,
  //     type,
  //     title,
  //     beds,
  //     minPrice,
  //     maxPrice,
  //     minArea,
  //     maxArea,
  //     purpose: localStorage.getItem("mysetprop"),
  //   };

  //   // Construct the URL with param data
  //   const queryParams = new URLSearchParams(param).toString();
  //   const allPropertiesUrl = `/all_properties?${queryParams}`;

  //   // Redirect to the all properties page
  //   window.location.href = allPropertiesUrl;

  //   console.log(allPropertiesUrl, "sjahdjkashdkjashdjkhasjkdhasdklhasdkl");
  // };

  const submitFilter = () => {
    const getValue = (id) => {
      const element = document.getElementById(id);
      return element ? element.value : null;
    };

    const city = getValue("city") || null;
    const type = getValue("type") || null;
    const title = getValue("title")?.trim() || null;
    const beds = getValue("beds") || null;
    const minPrice = getValue("minPrice")
      ? parseFloat(getValue("minPrice"))
      : null;
    const maxPrice = getValue("maxPrice")
      ? parseFloat(getValue("maxPrice"))
      : null;
    const minArea = getValue("minArea")
      ? parseFloat(getValue("minArea"))
      : null;
    const maxArea = getValue("maxArea")
      ? parseFloat(getValue("maxArea"))
      : null;
    const purpose = localStorage.getItem("mysetprop") || null;

    // Create the param object
    const param = {
      city,
      type,
      title,
      beds,
      minPrice,
      maxPrice,
      minArea,
      maxArea,
      purpose,
    };

    // Remove null or undefined values
    const filteredParams = Object.fromEntries(
      Object.entries(param).filter(
        ([key, value]) => value !== null && value !== ""
      )
    );

    // Construct the URL with filtered param data
    const queryParams = new URLSearchParams(filteredParams).toString();
    const allPropertiesUrl = `/all_properties?${queryParams}`;

    // Redirect to the all properties page
    window.location.href = allPropertiesUrl;

    console.log(allPropertiesUrl, "Generated URL");
  };

  return (
    <>
      <Swiper
        spaceBetween={60}
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper1"
      >
        <SwiperSlide>
          {/* Video Container */}
          <div>
            <Video />
          </div>

          <div className="home_slider pt-5">
            <div
              id="home_slider_content"
              className="container  justify-content-center align-items-center d-flex-1"
              style={{ height: "100vh", width: "100%" }}
            >
              <div
                className="container  justify-content-center align-items-center d-flex mobile"
                style={{ height: "100vh", width: "70%" }}
              >
                <div className="row w-100">
                  <div className="col-md-12">
                    <div className="text-center text-white">
                      {/* <h1 style={{ fontSize: "50px", fontWeight: "400" }} className="home-text">
                        {selectedOption === "sell"
                          ? "Search properties for sale in Pakistan"
                          : "Search properties to rent in Pakistan"}
                      </h1> */}

                      <div
                        className="d-flex flex-wrap pt-3 justify-content-center"
                        style={{ gap: "15px" }}
                      >
                        <div className="slider_num">
                          <button
                            className="btn text-white px-5 py-2 my-button"
                            id="mybuy"
                            style={{
                              backgroundColor:
                                localStorage.getItem("mysetprop") === "sell"
                                  ? "#3d3d3d"
                                  : "#4db749",
                            }}
                            onClick={() => handleClick("sell")}
                          >
                            BUY
                          </button>
                        </div>
                        <div className="slider_num">
                          <button
                            className="btn text-white px-5 py-2 my-button"
                            id="myrent"
                            style={{
                              backgroundColor:
                                localStorage.getItem("mysetprop") === "rent"
                                  ? "#3d3d3d"
                                  : "#4db749",
                            }}
                            onClick={() => handleClick("rent")}
                          >
                            RENT
                          </button>
                        </div>

                        <div className="dropdown">
                          <button
                            style={{
                              backgroundColor:
                                localStorage.getItem("mysetprop") === "invest"
                                  ? "#4DB749"
                                  : "#4DB749",
                            }}
                            className="btn btn-secondary dropdown-toggle px-5"
                            type="button"
                            id="invest"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            INVEST
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="invest"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/blue-world-city-islamabad/"
                              >
                                Blue World City
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/blue-world-trade-center"
                              >
                                Blue World Trade Center
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/akbar-vista">
                                Akbar Vista
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/pearl-business-center"
                              >
                                Pearl Business Center
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/27-arclink">
                                27 ArchLink
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/24-karat-crown"
                              >
                                24 Karat Crown
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div>
                        <div
                          className="py-4 slider_home2 rounded-3 mt-3"
                          style={{ backgroundColor: "#8B8B88" }}
                        >
                          <div className="row px-5">
                            <div className="col-md-3 col-sm-6 mb-3">
                              <select
                                className="form-select form-select-md py-2 rounded-3"
                                aria-label=".form-select-md example"
                                name="city"
                                id="city"
                                required
                                defaultValue={localStorage.getItem(
                                  "searchcity"
                                )}
                              >
                                <option>Select City</option>
                                <option value="Islamabad">Islamabad</option>
                                <option value="rawalpindi">Rawalpindi</option>
                              </select>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-3">
                              <input
                                className="form-control py-2"
                                type="search"
                                placeholder="Search Property"
                                id="title"
                                defaultValue={localStorage.getItem(
                                  "searchtitle"
                                )}
                              />
                            </div>
                            <div className="col-md-3 col-sm-6 mb-3">
                              <button
                                className="btn w-100 text-white px-5 py-2"
                                style={{
                                  backgroundColor: "#4DB749",
                                  opacity: "0.8",
                                }}
                                onClick={submitFilter}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12 ps-5 text-start">
                            <Link
                              to="#"
                              className="ps-4 pt-4"
                              style={{
                                color: "#BBE2B0",
                                textDecoration: "none",
                              }}
                              onClick={() => setMoreOptions(!moreOptions)}
                            >
                              + More Options
                            </Link>
                          </div>
                          {moreOptions && (
                            <div className="px-5">
                              <div className="row pt-3">
                                <div className="col-md-3 col-sm-6 mb-3">
                                  <select
                                    className="form-select form-select-md py-2"
                                    aria-label=".form-select-md example"
                                    id="type"
                                    name="type"
                                    defaultValue={localStorage.getItem(
                                      "searchtype"
                                    )}
                                  >
                                    <option>Any Type</option>
                                    <option value="house">House</option>
                                    <option value="flat">Flat</option>
                                    <option value="upperPortion">
                                      Upper Portion
                                    </option>
                                    <option value="lowerPortion">
                                      Lower Portion
                                    </option>
                                    <option value="room">Room</option>
                                    <option value="plot">Plot</option>
                                    <option value="farmHouse">
                                      Farm House
                                    </option>
                                    <option value="pentHouse">
                                      Pent House
                                    </option>
                                    <option value="resedentialPlot">
                                      Residential Plot
                                    </option>
                                    <option value="commercialPlot">
                                      Commercial Plot
                                    </option>
                                    <option value="agriculturalLand">
                                      Agricultural Land
                                    </option>
                                    <option value="industrialLand">
                                      Industrial Land
                                    </option>
                                    <option value="office">Office</option>
                                    <option value="shop">Shop</option>
                                    <option value="warehouse">Warehouse</option>
                                    <option value="factory">Factory</option>
                                    <option value="building">Building</option>
                                  </select>
                                </div>
                                <div className="col-md-3 col-sm-6 mb-3 position-relative rounded-container">
                                  <div
                                    className="bg-white rounded-container"
                                    onClick={() => setPricePop(!pricePop)}
                                  >
                                    <input
                                      className="form-control p-0 bg-white border-0 px-3 rounded-3"
                                      type="text"
                                      placeholder="Search Price"
                                      disabled
                                    />
                                    <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3">
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        {minPrice}
                                      </p>
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        to
                                      </p>
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        {maxPrice}
                                      </p>
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        ▼
                                      </p>
                                    </div>
                                  </div>

                                  {pricePop && (
                                    <div className="rounded-2 bg-white p-2 d-flex flex-column my-2 price-popup">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h6 className="text-dark">MIN</h6>
                                          <select
                                            className="form-control"
                                            name="minPrice"
                                            id="minPrice"
                                            value={minPrice}
                                            onChange={(e) =>
                                              setMinPrice(e.target.value)
                                            }
                                          >
                                            {priceOptions.map(
                                              (price, index) => (
                                                <option
                                                  key={index}
                                                  value={price}
                                                >
                                                  {price}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                        <div>
                                          <h6 className="text-dark">MAX</h6>
                                          <select
                                            className="form-control"
                                            name="maxPrice"
                                            id="maxPrice"
                                            value={maxPrice}
                                            onChange={(e) =>
                                              setMaxPrice(e.target.value)
                                            }
                                          >
                                            {priceOptions.map(
                                              (price, index) => (
                                                <option
                                                  key={index}
                                                  value={price}
                                                >
                                                  {price}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => setPricePop(false)}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-3 col-sm-6 mb-3 position-relative rounded-container">
                                  <div
                                    className="bg-white rounded-container"
                                    onClick={() => setBedPop(!bedPop)}
                                  >
                                    <input
                                      className="form-control p-0 bg-white border-0 px-3 rounded-3"
                                      type="text"
                                      placeholder="Search Area"
                                      disabled
                                    />
                                    <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3">
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        {minArea} {areaUnit}
                                      </p>
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        to
                                      </p>
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        {maxArea} {areaUnit}
                                      </p>
                                      <p className="p-0 m-0 pb-1 text-dark">
                                        ▼
                                      </p>
                                    </div>
                                  </div>

                                  {bedPop && (
                                    <div className="rounded-2 bg-white p-2 d-flex flex-column my-2 price-popup">
                                      <div className="select-container">
                                        <div>
                                          <h6 className="text-dark">MIN</h6>
                                          <select
                                            className="form-control rounded-select"
                                            name="minArea"
                                            id="minArea"
                                            value={minArea}
                                            onChange={(e) =>
                                              setMinArea(e.target.value)
                                            }
                                          >
                                            {areaOptions.map((area, index) => (
                                              <option key={index} value={area}>
                                                {area}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div>
                                          <h6 className="text-dark">MAX</h6>
                                          <select
                                            className="form-control rounded-select"
                                            name="maxArea"
                                            id="maxArea"
                                            value={maxArea}
                                            onChange={(e) =>
                                              setMaxArea(e.target.value)
                                            }
                                          >
                                            {areaOptions.map((area, index) => (
                                              <option key={index} value={area}>
                                                {area}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div>
                                          <h6 className="text-dark">UNIT</h6>
                                          <select
                                            className="form-control rounded-select"
                                            name="areaUnit"
                                            id="areaUnit"
                                            value={areaUnit}
                                            onChange={(e) =>
                                              setAreaUnit(e.target.value)
                                            }
                                          >
                                            {unitOptions.map((unit, index) => (
                                              <option
                                                key={index}
                                                value={unit.value}
                                              >
                                                {unit.label}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => setBedPop(false)}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-3 col-sm-6 mb-3">
                                  <select
                                    className="form-select form-select-md py-2"
                                    aria-label=".form-select-md example"
                                    id="beds"
                                    name="beds"
                                    defaultValue={localStorage.getItem(
                                      "searchbeds"
                                    )}
                                  >
                                    <option>All Beds</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HomeSlider;
