import React from 'react'
import LoginPage from "./../../components/Login/Login";
const Login = () => {
  return (
    <>
        <LoginPage/>
    </>
  )
}

export default Login