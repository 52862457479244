import React from "react";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        right: "10px",
        color: "#4db649",
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        left: "10px",
        color: "#4db649",
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

export { SampleNextArrow, SamplePrevArrow };
