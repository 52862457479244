import React, { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import ContactUs from "./contactus/ContactUs";
import Footer from "../components/footer/Footer";
import AskedQuestion from "../components/AskedQuestion/AskedQuestion";
import ChairMan from "../components/ChairMan";
import Addvertise from "../components/addvertise/Addvertise";
import Features from "../components/features/Features";
import FeaturesProject from "../components/features/FeaturesProject";
import Property from "../components/property/Property";
import YoutubeVideo from "../components/youtubeVideo/YoutubeVideo";
import Stories from "../pages/Story/Stories";
import Blogs from "../components/blogs/Blogs";

import LatestNews from "../components/LatestNews/LatestNews";
import DiscoverCity from "../components/discoverCity/DiscoverCity";
import { Link } from "react-router-dom";
import AgencySlider from "../components/agencySlider/AgencySlider";
import HomeSlider from "../components/HomeSlider/HomeSlider";
import PropertyCity from "../components/AskedQuestion/PropertyCity";
import MyAd from "./MyAd";
import Developers from "../components/developers/Developers";
import LastetBlog from "./../components/lastetBlog/LastetBlog";

const Home = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className=" position-fixed w-100" style={{ zIndex: "999999" }}>
        <TopNav />
        <BottomNav />
      </div>
      <HomeSlider />

      {/* <MyAd/> */}

      <Features />
      {/*<FeaturesProject />*/}
      <div class="container-fluid w-100 mb-5 mt-5 ">
        <div class="row">
          <div class="col-lg-12">
            <a href="">
              <img
                src={require("./../img/ads/bannner1234.jpg")}
                className="rounded-9 img-fluid "
                style={{ width: "100%", height: "100%" }}
              />
            </a>
          </div>
        </div>
      </div>
      {/* <AgencySlider /> */}
      {/* <Developers /> */}

      {/* <Stories /> */}
      <YoutubeVideo />
      <div class="container-fluid w-100   ">
        <div class="row">
          <div class="col-md-12">
            <a href="">
              <img
                src={require("./../img/ads/banner123.png")}
                className="rounded-9 img-fluid "
                style={{ width: "100%", height: "100%" }}
              />
            </a>
          </div>
        </div>
      </div>

      <LastetBlog />
      {/*<LatestNews />*/}
      {/* <DiscoverCity /> */}

      <AskedQuestion />

      <div class="container-fluid w-100 ">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center mb-5">
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "28.8px",
                }}
              >
                MAKKAAN LOCATION
              </h2>
              <img src={require("../../src/img/arrow.png")} />
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13298.669229538058!2d73.0846501!3d33.5620193!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9387687d1483%3A0x27e5b67977d88568!2sMakkaan.com!5e0!3m2!1sen!2s!4v1717150389448!5m2!1sen!2s"
              width="100%"
              height="350"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="container-fluid mb-5 mt-5">
        <div class="row">
          <div class="col-md-12">
            <a href="">
              <img
                src={require("./../img/ads/banner34.jpg")}
                className="rounded-3 img-fluid "
                style={{ width: "100%", height: "100%" }}
              />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
