import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api_url from "../../ApiUrl";

const Blogs = () => {
  const [myPost, setMyPost] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(api_url + `blog/get`).then((res) => {
      // console.log(
      //   res.data,
      //   "mery list blog==================================="
      // );
      setMyPost(res.data.data);
    });
  }, []);

  // Function to extract plain text from parsed HTML
  const getTextFromHTML = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  // Function to format the name
  const formatName = (name) => {
    return name
      .toLowerCase() // Optional: convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, ""); // Remove special characters
  };

  return (
    <>
      <div className="container-fluid">
        <br />
        <div className="row">
          {myPost.length > 0
            ? myPost.map((i) => (
                <div className="col-md-3 d-flex" key={i?.id}>
                  <div className="card flex-grow-1 d-flex flex-column">
                    <div className="position-relative position-relative-example">
                      <div
                        className="bg-white position-absolute top-0 start-0 p-2 rounded-bottom"
                        style={{ marginLeft: "20px" }}
                      >
                        <h5
                          style={{
                            fontWeight: "500",
                            fontSize: "16.92px",
                            lineHeight: "20.3px",
                          }}
                        >
                          {new Date(i?.createdAt).getDate()}
                        </h5>
                        <h6
                          className="text-muted"
                          style={{
                            fontWeight: "400",
                            fontSize: "14.07px",
                            lineHeight: "22.86px",
                          }}
                        >
                          {new Date(i?.createdAt).toLocaleString("en-us", {
                            month: "short",
                          })}
                        </h6>
                      </div>
                    </div>
                    <img
                      src={i?.image}
                      className="card-img-top"
                      alt={i?.name}
                    />
                    <div className="card-body flex-grow-1 d-flex flex-column">
                      <h5
                        className="card-title"
                        style={{
                          fontWeight: "700",
                          fontSize: "18px",
                          lineHeight: "22.6px",
                        }}
                      >
                        {i?.name &&
                          i.name
                            .replace(/-/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </h5>

                      <p
                        className="card-text text-muted"
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "22.42px",
                          marginTop: "auto",
                        }}
                      >
                        {getTextFromHTML(i?.description)
                          .split(/\s+/)
                          .slice(0, 10)
                          .join(" ")}
                        ....
                      </p>
                    </div>
                    <div className="card-footer" key={i.id}>
                      <Link
                        to={`/blog/${formatName(i.name)}`}
                        state={{ id: i.id }} // Pass the id as state
                        className="btn btn-outline-success p-2"
                        style={{
                          margin: "10px",
                          width: "100%",
                          textAlign: "center",
                          display: "inline-flex",
                          justifyContent: "center",
                        }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default Blogs;
