import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow from "./../../img/arrow.png";

const YoutubeVideo = () => {
  const [videos, setVideos] = useState([]);
  const API_KEY = "AIzaSyBqjH4WMRAjhsU-mtQjaDvx4BU2yEB2Qbc";
  const CHANNEL_ID = "UC4iIjbDmUjRhOk4ehLFkwaw";

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=6`
        );
        setVideos(response.data.items);
        console.log(response.data.items, "sdhajkshdjkashdjkhsjkdh");
      } catch (error) {
        console.error("Error fetching videos", error);
      }
    };

    fetchVideos();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div class="p-5 container-fluid">
        <div>
          <div className="text-center mb-4">
            <h2
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "28.8px",
              }}
            >
              MAKKAAN YOUTUBE
            </h2>
            <img src={arrow} alt="arrow" />
          </div>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite={true}
          >
            {videos.map((video) => (
              <div
                key={video.id.videoId}
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "10px",
                }}
              >
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.id.videoId}?enablejsapi=1&origin=https://makkaan.com`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={video.snippet.title}
                ></iframe>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default YoutubeVideo;
