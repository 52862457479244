import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import api_url from "../../ApiUrl";
import { Link } from "react-router-dom";

import { IoIosBed } from "react-icons/io";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { FaChartArea, FaBath } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";

const Features = () => {
  const [myPost, setMyPost] = useState([]);

  // useEffect(() => {
  //   axios.get(api_url + `property/get`).then((res) => {
  //     console.log(res.data, "list Data");
  //     setMyPost(res.data.data);
  //   });
  // }, []);
  useEffect(() => {
    axios.get(api_url + `property/get`).then((res) => {
      const featuredProperties = res.data.data.filter(
        (property) => property.Status === "active"
      );
      console.log(featuredProperties, "Featured Data");
      setMyPost(featuredProperties);
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const formatTitle = (title) => {
    const formattedTitle = title
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, ""); // Remove special characters

    // Remove the first hyphen if it exists
    return formattedTitle.startsWith("-")
      ? formattedTitle.substring(1)
      : formattedTitle;
  };

  return (
    <div
      className="container-fluid w-100 "
      style={{
        backgroundImage: `url('${require("./../../img/featurebg.png")}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="text-center mt-5">
        <p
          style={{
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "19.2px",
          }}
        >
          FEATURED PROPERTIES
        </p>
        <h2
          style={{
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "28.8px",
          }}
        >
          THIS WEEK'S TOP PROPERTIES
        </h2>
        <img src={require("./../../img/arrow.png")} alt="Arrow" />
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "16.8px",
            marginBottom: "3%",
          }}
        >
          Experience luxury living at its finest with our featured properties.
        </p>
      </div>

      {myPost.length > 0 ? (
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          itemClass="carousel-item-padding-40-px"
        >
          {myPost.map((property) => {
            const formattedTitle = formatTitle(property.title);

            return (
              <div
                className="container-fluid"
                style={{ marginBottom: "100px" }}
                key={property.id}
              >
                <div className="position-relative">
                  <Link
                    to={`/property/${formattedTitle}/${property.id}`}
                    onClick={() => {
                      localStorage.removeItem("mysetprop");
                    }}
                    className="text-dark"
                  >
                    <div
                      className="features bg-white p-2"
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        height: "435px", // Set a fixed height for the card
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          className="features_img"
                          style={{
                            overflow: "hidden",
                            borderRadius: 12,
                          }}
                        >
                          <img
                            src={property.image[0]}
                            height="230px"
                            width="100%"
                            alt=""
                          />
                        </div>

                        <div className="fire_logo">
                          <img
                            src={require("../../assets/images/fire.png")}
                            alt=""
                            width="30px"
                          />
                        </div>
                      </div>
                      <div className="top px-2">
                        <div className="d-flex pt-4 justify-content-between">
                          <div>
                            {property?.advancePayment &&
                              property?.installments != null &&
                              property?.installments !== 0 &&
                              property?.monthlyInstallments != null &&
                              property?.monthlyInstallments !== 0 && (
                                <span
                                  className="badge rounded-pill mb-3"
                                  style={{ backgroundColor: "#4db749" }}
                                >
                                  On Installments
                                </span>
                              )}

                            <h5
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              {property.title
                                ?.split(/\s+/)
                                .slice(0, 5)
                                .join(" ")}
                              ...
                            </h5>
                            <span
                              className=""
                              style={{
                                color: "#B9B9B9",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              <MdLocationOn size={20} color="#4db749" />
                              {property.location
                                ?.split(/\s+/)
                                .slice(0, 4)
                                .join(" ")}
                              ...
                            </span>
                          </div>
                          <div
                            className="text-center"
                            style={{
                              position: "absolute",
                              right: 15,
                            }}
                          >
                            <img
                              src={property.agencyLogo}
                              width="60px"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="pt-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <FaChartArea color="#B9B9B9" size={20} />
                            <h6
                              className="ps-2 mb-0 text-bold"
                              style={{ fontSize: "14px" }}
                            >
                              Area {property.size}
                            </h6>
                          </div>
                          {property?.beds && (
                            <div className="d-flex align-items-center">
                              <IoIosBed color="#B9B9B9" size={20} />
                              <h6
                                className="mb-0 ps-2 text-black text-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {property.beds} Beds
                              </h6>
                            </div>
                          )}
                          {property?.baths && (
                            <div className="d-flex align-items-center">
                              <FaBath color="#B9B9B9" size={20} />
                              <h6
                                className="mb-0 ps-2 text-black text-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {property.baths} Baths
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="footer px-2 pt-3 d-flex align-items-center justify-content-between">
                        <div className="">
                          <h5 className="house_price">PKR: {property.price}</h5>
                        </div>
                        <div className="d-flex ps-5" style={{ gap: "15px" }}>
                          <a href={`tel:${property.phone}`}>
                            <div className="">
                              <LuPhoneCall color="#4DB749" size={27} />
                            </div>
                          </a>
                          <a href={`mailto:${property.email}`}>
                            <div className="">
                              <MdEmail color="#4DB749" size={27} />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </Carousel>
      ) : null}
    </div>
  );
};

export default Features;
