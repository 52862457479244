import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";

import axios from "axios";
import api_url from "../ApiUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import Footer from "../components/footer/Footer";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBInput,
} from "mdb-react-ui-kit";

const Addlocation = () => {
  const [fillActive, setFillActive] = useState("tab1");

  const handleFillClick = (value: string) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };
  const [images, setImages] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const handleClick = () => {
    setShowFields(!showFields);
  };

  const [amenities, setAmenities] = useState({
    buildYear: "",
    tvLounge: "",
    storeRooms: "",
    laundryRoom: "",
    studyRoom: "",
    diningRoom: "",
    drawingRoom: "",
    powderRoom: "",
    servantQuarter: "",
    balcony: "",
    kitchen: "",
    furnished: "",
    semiFurnished: "",
    cornerPlot: "",
    basement: "",
    electricity: "",
    sewerage: "",
    watersupply: "",
    gas: "",
    security: "",
    internet: "",
    cable: "",
    school: "",
    hospital: "",
    mosque: "",
    restaurants: "",
    pool: "",
    lawn: "",
    lift: "",
    entry: "",
    hometheater: "",
    heating: "",
    cooling: "",
    accessibility: "",
    maintenance: "",
    floors: "",
    floorunit: "",
    facing: "",
  });

  const [featuress, setFeature] = useState("");
  const [advancePayment, setAdvancePayment] = useState("");
  const [installments, setInstallments] = useState("");
  const [monthlyInstallments, setMonthlyInstallments] = useState("");
  // const imageHandler = (e) => {
  //   setImages([...images, e.target.files[0]]);
  // };
  const imageHandler = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
    setImages((prevImages) => [...prevImages, ...selectedFiles]);
  };
  console.log(images, "==================>upload images");

  const handleRemoveItem = (id) => {
    setImages(images.filter((item) => item !== id));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAmenities((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { id, value } = e.target;
    setAmenities((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleBedsChange = (e) => {
    const value = Number(e.target.value);
    setBeds(value === 0 ? "Studio" : value);
  };

  const handleAddAmenities = () => {
    setFeature(amenities); // Assuming 'amenities' is the data you want to set
    console.log("Amenities Data:", amenities);
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    console.log("Featuress Data:", featuress);
  }, [featuress]);

  const navigate = useNavigate();
  const myId = localStorage.getItem("mainUserId");

  const [purpose, setPurpose] = useState(null);
  const [activeFeat, setActiveFeat] = useState(false);
  const [activeVid, setActiveVid] = useState(false);
  const [activeBed, setActiveBed] = useState(false);
  const [beds, setBeds] = useState("");
  const [type, setType] = useState("");
  const [baths, setBaths] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [title, setTitle] = useState("");

  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [sizeUnit, setSizeUnit] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [landline, setLandline] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  // Utility function to format the price
  const formatPrice = (price) => {
    const numPrice = parseFloat(price);
    if (isNaN(numPrice)) return "";
    if (numPrice >= 10000000) {
      return (
        (numPrice % 10000000 === 0
          ? numPrice / 10000000
          : (numPrice / 10000000).toFixed(2)) + " Crore"
      );
    } else if (numPrice >= 100000) {
      return (
        (numPrice % 100000 === 0
          ? numPrice / 100000
          : (numPrice / 100000).toFixed(2)) + " Lakh"
      );
    } else if (numPrice >= 1000) {
      return (
        (numPrice % 1000 === 0
          ? numPrice / 1000
          : (numPrice / 1000).toFixed(2)) + " Thousand"
      );
    } else {
      return numPrice.toString();
    }
  };

  // Utility function to format the advance payment
  const advanceformatPrice = (advancePayment) => {
    const numPrice = parseFloat(advancePayment);
    if (isNaN(numPrice)) return "";
    if (numPrice >= 10000000) {
      return (
        (numPrice % 10000000 === 0
          ? numPrice / 10000000
          : (numPrice / 10000000).toFixed(2)) + " Crore"
      );
    } else if (numPrice >= 100000) {
      return (
        (numPrice % 100000 === 0
          ? numPrice / 100000
          : (numPrice / 100000).toFixed(2)) + " Lakh"
      );
    } else if (numPrice >= 1000) {
      return (
        (numPrice % 1000 === 0
          ? numPrice / 1000
          : (numPrice / 1000).toFixed(2)) + " Thousand"
      );
    } else {
      return numPrice.toString();
    }
  };

  // Utility function to format the advance payment
  const monthlyformatPrice = (monthlyPayment) => {
    const numPrice = parseFloat(monthlyPayment);
    if (isNaN(numPrice)) return "";
    if (numPrice >= 10000000) {
      return (
        (numPrice % 10000000 === 0
          ? numPrice / 10000000
          : (numPrice / 10000000).toFixed(2)) + " Crore"
      );
    } else if (numPrice >= 100000) {
      return (
        (numPrice % 100000 === 0
          ? numPrice / 100000
          : (numPrice / 100000).toFixed(2)) + " Lakh"
      );
    } else if (numPrice >= 1000) {
      return (
        (numPrice % 1000 === 0
          ? numPrice / 1000
          : (numPrice / 1000).toFixed(2)) + " Thousand"
      );
    } else {
      return numPrice.toString();
    }
  };

  const toggleActiveFeat = () => {
    setActiveFeat(!activeFeat);
  };

  const toggleActiveVid = () => {
    setActiveVid(!activeVid);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleVideoChange = (event) => {
    const file = event.target.value;
    setVideo(file);
  };

  const generateTitle = () => {
    return ` ${size} ${sizeUnit} ${type}  ${purpose} in ${location} ${city}`;
  };

  useEffect(() => {
    setTitle(generateTitle());
  }, [purpose, type, size, sizeUnit, city, location]);

  const submit = async () => {
    if (loading) return; // Prevent multiple submissions
    if (myId.length === 0) {
      toast.error("Must sign in before adding property");
    } else if (!purpose) {
      toast.error("Must enter purpose");
    } else if (!featuress) {
      toast.error("Must enter features");
    } else if (title.length === 0) {
      toast.error("Must enter title");
    } else if (type.length === 0) {
      toast.error("Must select type");
    } else if (price.length === 0) {
      toast.error("Must enter price");
    } else if (location.length === 0) {
      toast.error("Must enter address");
    } else if (description.length === 0) {
      toast.error("Must enter description");
    } else if (size.length === 0) {
      toast.error("Must enter size");
    } else if (city.length === 0) {
      toast.error("Must enter city");
    } else if (phone.length === 0) {
      toast.error("Must enter phone");
    } else {
      let profilephoto = " ";

      try {
        let param = new FormData();
        images.map((ite) => {
          param.append("images", ite);
        });

        profilephoto = await axios.post(`${api_url}property/image`, param);

        console.log(profilephoto, "Uploaded images");
      } catch (error) {
        console.log(error);
      }

      const formattedPrice = formatPrice(price);
      const advanceformattedPrice = advanceformatPrice(advancePayment);
      const monthlyPrice = monthlyformatPrice(monthlyInstallments);

      const para = {
        title: title,
        type: type,
        feature: featuress,
        image: profilephoto.data.data,
        video: video,
        price: formattedPrice,
        description: description,
        size: `${size} ${sizeUnit}`,
        location: location,
        city: city,
        phone: phone,
        landline: landline,
        beds: beds,
        baths: baths,
        purpose: purpose,
        email: email,
        userId: myId,
        agencyName: myUser.agencyName,
        agencyLogo: myUser.agencyLogo,
        fullName: myUser.fullName,
        signAs: myUser.signAs,
        serviceArea: myUser.serviceArea,
        advancePayment: advanceformattedPrice,
        installments: installments,
        monthlyInstallments: monthlyPrice,
      };

      try {
        let response;

        if (id) {
          console.log("Request data:", para);
          // Update existing property
          response = await axios.put(`${api_url}property/update/${id}`, para);
          if (response.data.status === "ok") {
            console.log(response.data, "Property updated");
            toast.success("Property updated successfully!");
          } else {
            toast.error(response.data.message);
          }
        } else {
          // Create new property
          response = await axios.post(`${api_url}property/create`, para);
          if (response.data.status === "ok") {
            console.log(response.data, "Property created");
            toast.success("Property created successfully!");
          } else {
            toast.error(response.data.message);
          }
        }

        // Navigate to the dashboard after a few seconds
        setTimeout(() => {
          navigate("/property_managements");
        }, 4000); // 4000 milliseconds = 4 seconds
      } catch (error) {
        console.error("Error handling property:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false); // Reset loading state after the API call
      }
    }
  };

  const [myUser, setMyUser] = useState(null);

  useEffect(() => {
    axios.get(`${api_url}user/get/${myId}`).then((res) => {
      console.log(
        res.data.data,
        "userData ======================>>>>>>>>>>>>>>"
      );
      setMyUser(res.data.data);
    });
  }, [api_url, myId]);

  const handleCreateProperty = async () => {
    if (myUser) {
      const para = {
        // Add other required fields for property creation here
        agencyName: myUser.agencyName,

        // Include other necessary fields
      };
    }
  };

  useEffect(() => {
    axios
      .get(`${api_url}property/get/${id}`)
      .then((response) => {
        const property = response.data.data;

        console.log(property, "populated data");
        // Populate the state with the property data
        setTitle(property.title);
        setType(property.type);
        setFeature(property.feature);
        setPrice(property.price);

        setDescription(property.description);
        setSize(property.size.split(" ")[0]);
        setSizeUnit(property.size.split(" ")[1]);
        setCity(property.city);
        setLocation(property.location);
        setPhone(property.phone);
        setLandline(property.landline);
        setBeds(property.beds);
        setBaths(property.baths);
        setPurpose(property.purpose);
        setEmail(property.email);
        setImage(property.image);
        setVideo(property.video);
        setAdvancePayment(property.advancePayment);
        setInstallments(property.installments);
        setMonthlyInstallments(property.monthlyInstallments);
      })
      .catch((error) => {
        console.error("Error fetching property data:", error);
      });
  }, [id]);

  const handlePriceChange = (e) => {
    const inputPrice = e.target.value;
    setPrice(inputPrice);
  };

  const handleSizeUnitChange = (e) => {
    setSizeUnit(e.target.value);
  };
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  // Helper function to format keys into human-readable labels
  const formatLabel = (key) => {
    const formattedKey = key
      .replace(/([A-Z])/g, " $1") // Add a space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .replace(/([A-Z])/g, " $1"); // Add space before capital letters
    return formattedKey.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  return (
    <div
      className="position-relative add_location"
      style={{ backgroundColor: "#F3F7F9" }}
    >
      <div className="w-100">
        <TopNav />
        <BottomNav />
      </div>

      <ToastContainer />

      <div className="location">
        <div className="container  px-4 my-4 ">
          <div className="row">
            <div className="rounded-5 col-md-12 mb-4 bg-white border-0 shadow row p-3 py-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "1em",
                    color: "#4db749",
                  }}
                >
                  ADD PROPERTY
                </h2>
                <div className="text-center">
                  <img src={require("../img/arrow.png")} alt="Arrow" />
                </div>

                <div className="d-flex  mb-2">
                  <img
                    src={require("../assets/images/Group 3786.png")}
                    width="40px"
                    alt=""
                    height="40px"
                  />

                  <h5 className="ps-2 mt-2">LOCATION & PURPOSE</h5>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <label
                      htmlFor="exampleInputName"
                      className="form-label text-dark fw-bold"
                    >
                      Select Purpose
                    </label>
                  </div>
                  {/* <div className='text-end'>

                                    <FaUserTimes style={{ color: '#40C057' }} size={20} />

                                </div> */}
                </div>
                <div className="mb-4">
                  <span
                    id="prent"
                    className={`rounded-5 border px-4 py-1 me-3 ${
                      purpose === "For Rent" ? "selected" : ""
                    }`}
                    onClick={() => setPurpose("For Rent")}
                    style={{
                      backgroundColor:
                        purpose === "For Rent" ? "#4DB749" : "white",
                      color: purpose === "For Rent" ? "white" : "black",
                    }}
                  >
                    RENT
                  </span>
                  <span
                    id="psell"
                    className={`rounded-5 border px-4 py-1 me-3 ${
                      purpose === "For Sale" ? "selected" : ""
                    }`}
                    onClick={() => setPurpose("For Sale")}
                    style={{
                      backgroundColor:
                        purpose === "For Sale" ? "#4DB749" : "white",
                      color: purpose === "For Sale" ? "white" : "black",
                    }}
                  >
                    SELL
                  </span>
                </div>

                <div className="d-flex pt-4 justify-content-between align-items-center">
                  <div>
                    <label
                      htmlFor="exampleInputName"
                      className="form-label text-dark fw-bold"
                    >
                      Select Property Type
                    </label>
                  </div>
                  {/* <div className='text-end'>

                                    <i className='fa fa-user text-success'></i>
                                </div> */}
                </div>

                <div className="mb-4">
                  <>
                    <ul
                      className="nav nav-pills mb-3 border-bottom border"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item me-4" role="presentation">
                        <button
                          className="nav-link border px-4 py-1 me-3 active text-dark bg-white "
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={() => setType("home")}
                        >
                          Home
                        </button>
                      </li>

                      <li
                        className="nav-item me-4"
                        role="presentation"
                        style={{
                          display: purpose === "For Rent" ? "none" : "block",
                        }}
                      >
                        <button
                          className="nav-link text-dark fw-bold border px-4 py-1 me-3 bg-white"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                          onClick={() => setType("Plots")}
                        >
                          Plots
                        </button>
                      </li>
                      <li className="nav-item me-4" role="presentation">
                        <button
                          className="nav-link text-dark border px-4 py-1 me-3 bg-white"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                          onClick={() => setType("commercial")}
                        >
                          Commercial
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active "
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabIndex={0}
                      >
                        <div className="d-flex flex-wrap">
                          {type === "House" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("House")}
                            >
                              House
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("House")}
                            >
                              House
                            </div>
                          )}

                          {type === "Flat" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("Flat")}
                            >
                              Flat
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("Flat")}
                            >
                              Flat
                            </div>
                          )}
                          {type === "Upper Portion" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("Upper Portion")}
                            >
                              Upper Portion
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("Upper Portion")}
                            >
                              Upper Portion
                            </div>
                          )}

                          {type === "Lower Portion" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("Lower Portion")}
                            >
                              Lower Portion
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("Lower Portion")}
                            >
                              Lower Portion
                            </div>
                          )}

                          {type === "Room" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("Room")}
                            >
                              Room
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("Room")}
                            >
                              Room
                            </div>
                          )}

                          {type === "Farm House" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("Farm House")}
                            >
                              Farm House
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("Farm House")}
                            >
                              Farm House
                            </div>
                          )}

                          {type === "Pent House" ? (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              style={{
                                backgroundColor: "#4DB749",
                                color: "white",
                              }}
                              onClick={() => setType("Pent House")}
                            >
                              Pent House
                            </div>
                          ) : (
                            <div
                              className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                              onClick={() => setType("Pent House")}
                            >
                              Pent House
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex={0}
                      >
                        <div className="d-flex flex-wrap">
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Residential Plot"
                                  ? "#4DB749"
                                  : "white",
                              color:
                                type === "Residential Plot" ? "white" : "black",
                            }}
                            onClick={() => setType("Residential Plot")}
                          >
                            Residential Plot
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Commercial Plot"
                                  ? "#4DB749"
                                  : "white",
                              color:
                                type === "Commercial Plot" ? "white" : "black",
                            }}
                            onClick={() => setType("Commercial Plot")}
                          >
                            Commercial Plot
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Agricultural Land"
                                  ? "#4DB749"
                                  : "white",
                              color:
                                type === "Agricultural Land"
                                  ? "white"
                                  : "black",
                            }}
                            onClick={() => setType("Agricultural Land")}
                          >
                            Agricultural Land
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Industrial Land"
                                  ? "#4DB749"
                                  : "white",
                              color:
                                type === "Industrial Land" ? "white" : "black",
                            }}
                            onClick={() => setType("Industrial Land")}
                          >
                            Industrial Land
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabIndex={0}
                      >
                        <div className="d-flex flex-wrap">
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Office" ? "#4DB749" : "white",
                              color: type === "Office" ? "white" : "black",
                            }}
                            onClick={() => setType("Office")}
                          >
                            Office
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Shop" ? "#4DB749" : "white",
                              color: type === "Shop" ? "white" : "black",
                            }}
                            onClick={() => setType("Shop")}
                          >
                            Shop
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Ware House" ? "#4DB749" : "white",
                              color: type === "Ware House" ? "white" : "black",
                            }}
                            onClick={() => setType("Ware House")}
                          >
                            Ware House
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Factory" ? "#4DB749" : "white",
                              color: type === "Factory" ? "white" : "black",
                            }}
                            onClick={() => setType("Factory")}
                          >
                            Factory
                          </div>
                          <div
                            className=" rounded-5 border px-4 py-1 me-3 mb-2 "
                            style={{
                              backgroundColor:
                                type === "Building" ? "#4DB749" : "white",
                              color: type === "Building" ? "white" : "black",
                            }}
                            onClick={() => setType("Building")}
                          >
                            Building
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <div>
                        <label
                          htmlFor="exampleInputName"
                          className="form-label text-dark fw-bold"
                        >
                          City
                        </label>
                      </div>
                      {/* <select class="form-select py-2 border-0 rounded-1" aria-label="Default select example" id='city' onChange={(e) => setCity(e.target.value)}> */}
                      <select
                        class="form-select py-2 border-0 rounded-1"
                        aria-label="Default select example"
                        id="city"
                        value={city} // Set value to the state variable `city`
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option value="" disabled>
                          Select The City
                        </option>
                        <option value="Islamabad">Islamabad</option>
                        <option value="Rawalpindi">Rawalpindi</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <div>
                        <label
                          htmlFor="exampleInputName"
                          className="form-label text-dark fw-bold"
                        >
                          Location
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control border-0 py-2"
                        id="location"
                        placeholder="Location"
                        value={location} // Set the value attribute to the state variable `location`
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-5 col-md-6 mb-4 bg-white border-0 shadow row p-3 py-5 me-2">
              <div className="row   ">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <img
                      src={require("../assets/images/moneys.png")}
                      width="40px"
                      alt=""
                      height="40px"
                    />
                    <h5 className="ps-2 mt-2">PRICE & AREA</h5>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label text-dark fw-bold"
                      >
                        Area Size
                      </label>
                    </div>
                  </div>

                  <div class="mb-3 d-flex gap-3">
                    <input
                      type="number"
                      class="form-control py-2 border-0"
                      id="size"
                      placeholder="Enter Unit"
                      onChange={handleSizeChange}
                      value={size}
                    />
                    Unit
                    <select
                      class="form-select border-0 w-25"
                      aria-label="Default select example"
                      onChange={handleSizeUnitChange}
                      value={sizeUnit}
                    >
                      <option value="" disabled>
                        Select Size Unit
                      </option>
                      <option value="Marla">Marla</option>
                      <option value="Kanal">Kanal</option>
                    </select>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label text-dark fw-bold"
                      >
                        Total {purpose === "For Sale" ? "Price" : "Rent"}
                      </label>
                    </div>
                    {/* <div className='text-end'>

                                    <img src={require('../assets/images/destination.png')} width="25px" height="25px" alt='' />
                                </div> */}
                  </div>

                  <div class="mb-3 d-flex gap-3">
                    <input
                      type="number"
                      class="form-control py-2 border-0"
                      id="price"
                      placeholder={
                        purpose === "For Sale" ? "Enter Price" : "Enter Rent"
                      }
                      onChange={handlePriceChange}
                      value={price}
                    />
                    <span>PKR: {formatPrice(price)}</span>

                    <input
                      type="text"
                      class="form-control w-25 py-2 border-0"
                      id="price"
                      placeholder="PKR"
                      disabled
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="d-flex">
                      <img
                        src={require("../assets/images/moneys.png")}
                        width="40px"
                        alt=""
                        height="40px"
                      />
                      <h5 className="ps-2 mt-2">INSTALLMENTS PRICE</h5>
                    </div>
                  </div>

                  <div className="mb-4 mt-4">
                    <span
                      id="prent"
                      className={`rounded-5 border px-4 py-1 me-3 ${
                        showFields ? "active" : ""
                      }`}
                      onClick={handleClick}
                      style={{
                        cursor: "pointer",
                        backgroundColor: showFields ? "#007bff" : "transparent",
                        color: showFields ? "#fff" : "#000",
                      }}
                    >
                      Click On if listing is available on installments
                    </span>
                  </div>

                  {showFields && (
                    <div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                          <label
                            htmlFor="exampleInputName"
                            className="form-label text-dark fw-bold"
                          >
                            Advance Payment
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 d-flex gap-3">
                        <input
                          type="number"
                          className="form-control py-2 border-0"
                          id="advancePayment"
                          placeholder="Enter Advance Payment"
                          onChange={(e) => setAdvancePayment(e.target.value)}
                          value={advancePayment}
                        />
                        <span>PKR: {advanceformatPrice(advancePayment)}</span>

                        <input
                          type="text"
                          className="form-control w-25 py-2 border-0"
                          id="advancePaymentDisplay"
                          placeholder="PKR"
                          disabled
                        />
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                          <label
                            htmlFor="installments"
                            className="form-label text-dark fw-bold"
                          >
                            No of Installments
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 d-flex gap-3">
                        <input
                          type="number"
                          className="form-control py-2 border-0"
                          id="installments"
                          placeholder="Enter No of Installments"
                          onChange={(e) => setInstallments(e.target.value)}
                          value={installments}
                        />
                        {/* <span>
                          PKR: {installmentsformatPrice(installments)}
                        </span> */}

                        {/* <input
                          type="text"
                          className="form-control w-25 py-2 border-0"
                          id="installmentsDisplay"
                          placeholder="PKR"
                          disabled
                        /> */}
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                          <label
                            htmlFor="monthlyInstallments"
                            className="form-label text-dark fw-bold"
                          >
                            Monthly Installments
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 d-flex gap-3">
                        <input
                          type="number"
                          className="form-control py-2 border-0"
                          id="monthlyInstallments"
                          placeholder="Enter Monthly Installments"
                          onChange={(e) =>
                            setMonthlyInstallments(e.target.value)
                          }
                          value={monthlyInstallments}
                        />
                        <span>
                          PKR: {monthlyformatPrice(monthlyInstallments)}
                        </span>

                        <input
                          type="text"
                          className="form-control w-25 py-2 border-0"
                          id="monthlyInstallmentsDisplay"
                          placeholder="PKR"
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="rounded-5 col-md-6 mb-4 bg-white border-0 mx-auto shadow row p-3 py-5">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <img
                      src={require("../assets/images/notification-status.png")}
                      width="40px"
                      alt=""
                      height="40px"
                    />
                    <h5 className="ps-2 mt-2">FEATURE & AMENITIES</h5>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {type === "Commercial Plot" ||
                  type === "Residential Plot" ||
                  type === "Agricultural Land" ||
                  type === "Industrial Land" ||
                  type === "Plots" ||
                  type === "commercial" ||
                  type === "Office" ||
                  type === "Shop" ||
                  type === "Ware House" ||
                  type === "Building" ||
                  type === "Factory" ? null : (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div>
                            <label
                              htmlFor="exampleInputName"
                              className="form-label text-dark fw-bold"
                            >
                              Bedrooms
                            </label>
                          </div>
                        </div>

                        <div class="mb-3 d-flex flex-wrap">
                          {/* {beds==='Studio'?<div className=' rounded-5  border px-4 py-1 me-3 mb-2' onClick={() => setBeds('Studio')} style={{  borderRadius: '20px',backgroundColor:'#4DB749' }}><span style={{fontSize:"10px"}}>Studio</span></div>:<div className=' rounded-5  border px-3 py-1 me-1 mb-2' onClick={() => setBeds('Studio')} style={{ borderRadius: '20px' }}><span style={{fontSize:"12px"}}>Studio</span></div>} */}

                          <select
                            value={beds}
                            onChange={handleBedsChange}
                            className="form-select"
                            style={{
                              width: "100%",
                              borderRadius: "20px",
                              backgroundColor: "#4DB749",
                            }}
                          >
                            <option value="">Select</option>
                            <option value={0}>Studio</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex  justify-content-between align-items-center mb-2">
                          <div>
                            <label
                              htmlFor="exampleInputName"
                              className="form-label text-dark fw-bold"
                            >
                              Bathrooms
                            </label>
                          </div>
                        </div>

                        <div class="mb-3 d-flex">
                          <select
                            value={baths}
                            onChange={(e) => setBaths(Number(e.target.value))}
                            className="form-select"
                            style={{
                              width: "100%",
                              borderRadius: "20px",
                              backgroundColor: "#4DB749",
                            }}
                          >
                            <option value="">Select</option>

                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>

                          {/* {baths===6?<div className='border  me-3 mb-2 d-flex align-items-center justify-content-center' onClick={() => setBaths(6)} style={{ width: '20px', height: '20px', borderRadius: '50%',backgroundColor:'#4DB749' }}>6</div>:<div className='border  me-3 mb-2 d-flex align-items-center justify-content-center' onClick={() => setBaths(6)} style={{ width: '20px', height: '20px', borderRadius: '50%' }}>6</div>} */}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* End of bed and bath amenties */}

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label text-dark fw-bold"
                      >
                        <h6>Feature & Amenities</h6>
                      </label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <small>
                      Add aditional features e.g parking spaces, waste disposal,
                      internet etc.
                    </small>
                  </div>

                  <div>
                    {/* <button
                      className="btn text-white py-1"
                      style={{ backgroundColor: "#4DB749" }}
                      onClick={toggleActiveFeat}
                    >
                      Add Amenities
                      </button> */}

                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Add Features and Amenities
                    </button>
                    {/* Conditionally display the amenities data in a table */}
                    {Object.values(amenities).some((value) => value) && (
                      <div className="mt-4">
                        <h4>Selected Amenities:</h4>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Amenity</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(amenities).map(
                              ([key, value]) =>
                                value && (
                                  <tr key={key}>
                                    <td>{formatLabel(key)}</td>
                                    <td>{value}</td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1
                              class="modal-title fs-5"
                              id="staticBackdropLabel"
                            >
                              Feature and Amenities
                            </h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>

                          <div class="modal-body">
                            <MDBTabs fill className="mb-3">
                              <MDBTabsItem>
                                <MDBTabsLink
                                  onClick={() => handleFillClick("tab1")}
                                  active={fillActive === "tab1"}
                                >
                                  Primary Features
                                </MDBTabsLink>
                              </MDBTabsItem>
                              <MDBTabsItem>
                                <MDBTabsLink
                                  onClick={() => handleFillClick("tab2")}
                                  active={fillActive === "tab2"}
                                >
                                  Utilities
                                </MDBTabsLink>
                              </MDBTabsItem>
                              <MDBTabsItem>
                                <MDBTabsLink
                                  onClick={() => handleFillClick("tab3")}
                                  active={fillActive === "tab3"}
                                >
                                  communication
                                </MDBTabsLink>
                              </MDBTabsItem>
                              <MDBTabsItem>
                                <MDBTabsLink
                                  onClick={() => handleFillClick("tab4")}
                                  active={fillActive === "tab4"}
                                >
                                  LandMarks Nearby
                                </MDBTabsLink>
                              </MDBTabsItem>

                              <MDBTabsItem>
                                <MDBTabsLink
                                  onClick={() => handleFillClick("tab5")}
                                  active={fillActive === "tab5"}
                                >
                                  Secondary Features
                                </MDBTabsLink>
                              </MDBTabsItem>
                            </MDBTabs>

                            <MDBTabsContent>
                              <MDBTabsPane open={fillActive === "tab1"}>
                                <div classNmae="container">
                                  <div className="row">
                                    <div class="row g-2">
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            type="number"
                                            id="buildYear"
                                            class="form-control"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Build Year
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            class="form-control"
                                            type="number"
                                            id="tvLounge"
                                            value={amenities.tvLounge} // Bind the value to state
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Tv lounge
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            class="form-control"
                                            type="number"
                                            id="storeRooms"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Store Rooms
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row g-2">
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            type="number"
                                            id="laundryRoom"
                                            onChange={handleChange}
                                            class="form-control"
                                          />
                                          <label for="floatingInputGrid">
                                            Laundary Room
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            class="form-control"
                                            type="number"
                                            id="studyRoom"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Study Room
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md">
                                        <div className="form-floating">
                                          <input
                                            className="form-control"
                                            type="number"
                                            id="diningRoom"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Dining Room
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row g-2">
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            type="number"
                                            id="drawingRoom"
                                            onChange={handleChange}
                                            class="form-control"
                                          />
                                          <label for="floatingInputGrid">
                                            Drawing Room
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            class="form-control"
                                            type="number"
                                            id="powderRoom"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Powder Room
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md">
                                        <div className="form-floating">
                                          <input
                                            className="form-control"
                                            type="number"
                                            id="servantQuarter"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Servant quarter
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row g-2">
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            type="number"
                                            id="balcony"
                                            onChange={handleChange}
                                            class="form-control"
                                          />
                                          <label for="floatingInputGrid">
                                            Balcony
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md">
                                        <div class="form-floating">
                                          <input
                                            class="form-control"
                                            type="number"
                                            id="kitchen"
                                            onChange={handleChange}
                                          />
                                          <label for="floatingInputGrid">
                                            Kitchen
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md">
                                        <div class="form-floating">
                                          <select
                                            className="form-select"
                                            id="furnished"
                                            aria-label="Floating label select example"
                                            onChange={handleSelectChange}
                                          >
                                            <option selected disabled>
                                              Furnished
                                            </option>
                                            <option value="yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <label for="floatingSelectGrid">
                                            Furnished
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row g-2">
                                      <div className="col-md">
                                        <div class="form-floating">
                                          <select
                                            className="form-select"
                                            id="semiFurnished"
                                            aria-label="Floating label select example"
                                            onChange={handleSelectChange}
                                          >
                                            <option selected disabled>
                                              Semi Furnished
                                            </option>
                                            <option value="yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <label for="floatingSelectGrid">
                                            Semi Furnished ?
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md">
                                        <div class="form-floating">
                                          <select
                                            className="form-select"
                                            id="cornerPlot"
                                            aria-label="Floating label select example"
                                            onChange={handleSelectChange}
                                          >
                                            <option selected disabled>
                                              Corner Plot
                                            </option>
                                            <option value="yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <label for="floatingSelectGrid">
                                            Corner Plot ?
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md">
                                        <div class="form-floating">
                                          <select
                                            className="form-select"
                                            id="basement"
                                            aria-label="Floating label select example"
                                            onChange={handleSelectChange}
                                          >
                                            <option selected disabled>
                                              Basement
                                            </option>
                                            <option value="yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <label for="floatingSelectGrid">
                                            Basement ?
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </MDBTabsPane>
                              <MDBTabsPane open={fillActive === "tab2"}>
                                <div class="row g-2">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="electricity"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Electricity
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Electricity ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="sewerage"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Sewerage
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Sewerage ?
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="row g-2 mt-2">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="watersupply"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Water Supply
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Water Supply ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="gas"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Gas
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Gas ?
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="row g-2 mt-2">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="security"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Security
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Security ?
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </MDBTabsPane>
                              <MDBTabsPane open={fillActive === "tab3"}>
                                <div class="row g-2">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="internet"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Internet Connection
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Internet Connection ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="cable"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Satellite or Cable Tv
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Satellite or Cable Tv
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </MDBTabsPane>
                              <MDBTabsPane open={fillActive === "tab4"}>
                                <div class="row g-2">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="school"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          School
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        School?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="hospital"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Hospital
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Hospital?
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="row g-2 mt-3">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="mosque"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Mosque
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Mosque?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="restaurants"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Restaurants
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Restaurants?
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </MDBTabsPane>

                              <MDBTabsPane open={fillActive === "tab5"}>
                                <div class="row g-2 mt-3">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="pool"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Swimming Pool
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Swimming Pool ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="lawn"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Lawn
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Lawn ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="lift"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Elevator/Lift
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Elevator/Lift ?
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="row g-2 mt-3">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="entry"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Seperate Entry
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Seperate Entry ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="hometheater"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Home Theater
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Home Theater ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="heating"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Central Heating
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Central Heating?
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="row g-2 mt-3">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        className="form-select"
                                        id="cooling"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Central Cooling
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Central Cooling ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="accessibility"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Accessibility
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Accessibility ?
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="maintenance"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Maintenance
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Maintenance?
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="row g-2 mt-3">
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <input
                                        type="number"
                                        id="floors"
                                        onChange={handleChange}
                                        class="form-control"
                                      />
                                      <label for="floatingInputGrid">
                                        Number of Floors
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <input
                                        type="number"
                                        id="floorunit"
                                        onChange={handleChange}
                                        class="form-control"
                                      />
                                      <label for="floatingInputGrid">
                                        Which Floor is Your Unit On
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md">
                                    <div class="form-floating">
                                      <select
                                        class="form-select"
                                        id="facing"
                                        aria-label="Floating label select example"
                                        onChange={handleSelectChange}
                                      >
                                        <option selected disabled>
                                          Facing
                                        </option>
                                        <option value="North">North</option>
                                        <option value="North-East">
                                          North East
                                        </option>
                                        <option value="East"> East</option>
                                        <option value="South">South</option>
                                        <option value="South-West">
                                          South West
                                        </option>
                                        <option value="West">West</option>
                                        <option value="North-West">
                                          North West
                                        </option>
                                      </select>
                                      <label for="floatingSelectGrid">
                                        Facing ?
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </MDBTabsPane>
                            </MDBTabsContent>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={handleAddAmenities}
                              data-bs-dismiss="modal"
                            >
                              Add Amenities
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {activeFeat ? (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                      <textarea
                        class="form-control location_btn"
                        rows="8"
                        placeholder="Add Feature here"
                        id="feature"
                        style={{ height: "160px" }}
                        onChange={(e) => setFeature(e.target.value)}
                      ></textarea>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="rounded-5 col-md-6 mb-4 bg-white border-0 shadow row p-3 py-5 me-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex">
                  <img
                    src={require("../assets/images/info-circle.png")}
                    width="40px"
                    alt=""
                    height="40px"
                  />
                  {/* <i><FaHospitalUser size={35} color='#4DB749' /></i> */}
                  <h5 className="ps-2 mt-2">ADD INFORMATION</h5>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 ">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <label
                      htmlFor="exampleInputName"
                      className="form-label text-dark fw-bold"
                    >
                      Title
                    </label>

                    <h5> {generateTitle()}</h5>
                  </div>
                </div>

                {/* <div class="mb-3 d-flex gap-3">
                  <input
                    type="text"
                    class="form-control border-0"
                    id="title"
                    placeholder="Enter Beautiful Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div> */}

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <label
                      htmlFor="exampleInputName"
                      className="form-label text-dark fw-bold"
                    >
                      Description
                    </label>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="form-group">
                    <textarea
                      class="form-control location_btn"
                      rows="8"
                      placeholder="Describe your property, its features, area in it. etc."
                      id="description"
                      value={description}
                      style={{ height: "160px" }}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12"></div>
            </div>

            <div className="rounded-5 col-md-6 mb-4 bg-white border-0 shadow row p-3 py-5 mx-auto">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex">
                  <img
                    src={require("../assets/images/gallery-add.png")}
                    width="40px"
                    alt=""
                    height="40px"
                  />
                  <h5 className="ps-2 mt-2">PROPERTY IMAGES & VIDEOS</h5>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <label
                      htmlFor="exampleInputName"
                      className="form-label text-dark fw-bold"
                    >
                      Upload Images of your property
                    </label>
                  </div>
                </div>

                <div className="row rounded-3  ">
                  <div>
                    <label>Upload Images of your property</label>
                    <input
                      id="image"
                      className="form-control"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => imageHandler(e)}
                    />
                    <p>
                      Max Size: 5MB,.jpg,.png format only
                      <br></br>
                      Ads with pictures get 5x more views and leads
                    </p>
                  </div>
                  {/* <p>Max Size: 5MB,.jpg,.png format only</p> */}
                  {/* <div className='col-md-6'>
                                    <small>Ads with pictures get 5x more views and leads</small>
                                    <br />
                                    <br />
                                    <small>Upload good quality pictures with proper lighting.</small>
                                    <br />
                                    <br />
                                    <small>Cover all areas of your property</small>
                                </div> */}

                  <div className="col-md-12 d-flex gap-2 my-3">
                    {images &&
                      images?.map((i) => {
                        return (
                          <>
                            <div className="col-md-2  position-relative">
                              <img
                                src={URL.createObjectURL(i)}
                                width="90px"
                                height="90px"
                                className="border rounded-3"
                              />
                              <span
                                className="border position-absolute p-1 py-0 rounded-circle bg-dark text-white"
                                style={{
                                  left: "80px",
                                  lineHeight: "15px",
                                  fontSize: "x-small",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveItem(i)}
                              >
                                x
                              </span>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>

                <div className="row  rounded-3 ">
                  <div className="col-md-12 ">
                    <h6>Add videos of your property(Optional)</h6>

                    {/* <p style={{ Color: '#CACACA' }}>Add videos of your property from youtube. Upload on youtube and paste the link below.</p> */}
                    {/* <button className='btn bg-white px-5 ' style={{backgroundColor:type==='upperPortion'?'#4DB749':'white'}} onClick={toggleActiveVid}>Add Video</button> */}

                    {activeVid ? (
                      <input
                        type="text"
                        class="form-control border-0 mt-3"
                        placeholder="Enter youtube video link"
                        id="video"
                        onChange={(e) => handleVideoChange(e)}
                      />
                    ) : (
                      <input
                        id="image"
                        className="form-control"
                        type="file"
                        onChange={(e) => toggleActiveVid(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-5 col-md-6 mb-4 bg-white border-0 shadow row p-3 py-5 me-2">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex">
                    <img
                      src={require("../assets/images/route-square.png")}
                      width="40px"
                      alt=""
                      height="40px"
                    />
                    <h5 className="ps-2 mt-2">CONTACT INFORMATION</h5>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <br></br>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label text-dark fw-bold"
                      >
                        Email
                      </label>
                    </div>
                  </div>

                  <div class="mb-3 d-flex gap-3">
                    <input
                      type="text"
                      class="form-control border-0"
                      id="email"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label text-dark fw-bold"
                      >
                        Mobile
                      </label>
                    </div>
                  </div>

                  <div class="mb-3 d-flex gap-3">
                    {/* <input
                      type="text"
                      class="form-control border-0"
                      id="code"
                      placeholder="+92"
                      style={{ width: "15%" }}
                    /> */}
                    <input
                      type="text"
                      class="form-control border-0"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label text-dark fw-bold"
                      >
                        Landline(Optional)
                      </label>
                    </div>
                  </div>

                  <div class="mb-3 d-flex gap-3">
                    {/* <input
                      type="text"
                      class="form-control border-0"
                      id="code"
                      placeholder="051"
                      style={{ width: "15%" }}
                    /> */}
                    <input
                      type="text"
                      class="form-control  py-2 border-0"
                      id="landline"
                      onChange={(e) => setLandline(e.target.value)}
                    />
                  </div>

                  <div className="text-end">
                    <button
                      type="button"
                      className="btn text-white px-5 py-2 rounded-3"
                      style={{ backgroundColor: "#4DB749" }}
                      onClick={submit}
                      disabled={loading}
                    >
                      {id ? "UPDATE" : "SUBMIT"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mx-auto"></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Addlocation;
