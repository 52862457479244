import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { IoIosBed } from "react-icons/io";
import { MdEmail } from "react-icons/md";

import { MdLocationOn } from "react-icons/md";
import { FaChartArea, FaBath } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import TopNav from "../TopNav";
import BottomNav from "../BottomNav";
import Footer from "../footer/Footer";
import axios from "axios";
import api_url from "../../ApiUrl";

const priceOptions = [
  0,
  5000,
  10000,
  20000,
  30000,
  40000,
  50000,
  75000,
  100000,
  150000,
  200000,
  300000,
  400000,
  500000,
  750000,
  1000000,
  1500000,
  2000000,
  3000000,
  4000000,
  5000000,
  10000000,
  20000000,
  30000000,
  40000000,
  50000000,
  "Any",
];

const areaOptions = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  15,
  20,
  50,
  100,
  200,
  500,
  "Any",
];

const unitOptions = [
  { label: "Marla", value: "marla" },
  { label: "Kanal", value: "kanal" },
];
const AllProperties = () => {
  const [myPost, setMyPost] = useState([]);

  const [minPrice, setMinPrice] = useState(
    localStorage.getItem("searchminprice") || 0
  );
  const [maxPrice, setMaxPrice] = useState(
    localStorage.getItem("searchmaxprice") || "Any"
  );

  const [minArea, setMinArea] = useState(
    localStorage.getItem("searchminarea") || 0
  );
  const [maxArea, setMaxArea] = useState(
    localStorage.getItem("searchmaxarea") || "Any"
  );
  const [areaUnit, setAreaUnit] = useState(
    localStorage.getItem("searchareaunit") || "Marla"
  );

  useEffect(() => {
    localStorage.setItem("searchminarea", minArea);
  }, [minArea]);

  useEffect(() => {
    localStorage.setItem("searchmaxarea", maxArea);
  }, [maxArea]);

  useEffect(() => {
    localStorage.setItem("searchminprice", minPrice);
  }, [minPrice]);

  useEffect(() => {
    localStorage.setItem("searchmaxprice", maxPrice);
  }, [maxPrice]);

  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("mysetprop") || "sell"
  );

  useEffect(() => {
    const savedOption = localStorage.getItem("mysetprop");
    if (savedOption) {
      setSelectedOption(savedOption);
    }
  }, []);

  const handleClick = (option) => {
    localStorage.setItem("mysetprop", option);
    setSelectedOption(option);
  };

  useEffect(() => {
    axios.get(api_url + `property/get`).then((res) => {
      console.log(res.data, "==========>img");
      setMyPost(res.data.data);
    });
  }, []);

  const submitFilter = () => {
    const city = document.getElementById("city");
    const type = document.getElementById("type");
    const title = document.getElementById("title");
    const beds = document.getElementById("beds");
    // const minPrice = document.getElementById("minPrice");
    // const maxPrice = document.getElementById("maxPrice");
    // const minArea = document.getElementById("minArea");
    // const maxArea = document.getElementById("maxArea");

    console.log("Min Area:", minArea?.value);
    console.log("Max Area:", maxArea?.value);

    const param = {
      city: city.value || null,
      type: type.value || null,
      title: title.value.trim() || null, // trim to remove extra spaces
      beds: beds.value ? beds.value : null, // ensure integer value or null
      minPrice: minPrice?.value ? parseFloat(minPrice.value) : null, // parse as float
      maxPrice: maxPrice?.value ? parseFloat(maxPrice.value) : null,
      maxArea: maxArea?.value ? parseFloat(maxArea.value) : null,
      minArea: minArea?.value ? parseFloat(minArea.value) : null,
      purpose: localStorage.getItem("mysetprop"), // add buy/rent type
    };

    if (minPrice) {
    }
    console.log(param, "000000000000000000000000000000");

    axios
      .post(api_url + `property/filter`, param)
      .then((res) => {
        debugger;
        console.log("API response data:", res.data); // Check what you received
        setMyPost(res.data.data); // Assuming the data is in res.data.data
      })
      .catch((error) => {
        console.error("Error fetching filtered properties: ", error);
      });
  };

  useEffect(() => {
    // Retrieve the param data from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const searchparam = {};
    for (const [key, value] of queryParams.entries()) {
      searchparam[key] = value;
    }

    // Fetch search results using param data
    axios
      .post(api_url + `property/filter`, searchparam)
      .then((res) => {
        console.log("API response data:", res.data);
        setMyPost(res.data.data);
        console.log(
          res.data.data,
          "dasjhdjkashdjkhaskjdhasjkdhjkashdjkashdjkhask"
        );
      })
      .catch((error) => {
        console.error("Error fetching filtered properties: ", error);
      });
  }, []);

  const [pricePop, setPricePop] = useState(false);
  const [bedPop, setBedPop] = useState(false);

  const formatTitle = (title) => {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, ""); // Remove special characters
  };

  return (
    <>
      <TopNav />
      <BottomNav />
      <Swiper
        spaceBetween={60}
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper1"
      >
        <SwiperSlide>
          <div
            className="home_slider"
            style={{
              backgroundImage: `linear-gradient(rgba(139, 139, 136,0.3),rgba(139, 139, 136,0.3)),url(${require("../../assets/images/Main.jpg")})`,
              width: "100%",
              height: "100vh",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div
              className="container  justify-content-center align-items-center d-flex"
              style={{ height: "100vh", width: "100%" }}
            >
              <div className="row w-100">
                <div className="col-md-12">
                  <div className="text-center text-white">
                    <h1 style={{ fontSize: "5em", fontWeight: "400" }}>
                      {selectedOption === "sell"
                        ? "Find your perfect space to Buy"
                        : "Find your perfect space to Rent"}
                    </h1>

                    <div
                      className="d-flex pt-3 justify-content-center"
                      style={{ gap: "5px" }}
                    >
                      <div className="">
                        <button
                          className="btn text-white px-5 py-2"
                          id="mybuy"
                          style={{
                            backgroundColor:
                              localStorage.getItem("mysetprop") === "sell"
                                ? "#3d3d3d"
                                : "#4db749",
                          }}
                          onClick={() => handleClick("sell")}
                        >
                          BUY
                        </button>
                      </div>
                      <div className="">
                        <button
                          className="btn text-white px-5 py-2"
                          id="myrent"
                          style={{
                            backgroundColor:
                              localStorage.getItem("mysetprop") === "rent"
                                ? "#3d3d3d"
                                : "#4db749",
                          }}
                          onClick={() => handleClick("rent")}
                        >
                          RENT
                        </button>
                      </div>

                      <button
                        style={{
                          backgroundColor:
                            localStorage.getItem("mysetprop") === "invest"
                              ? "#4DB749"
                              : "#4DB749",
                        }}
                        class="btn btn-secondary dropdown-toggle px-5"
                        type="button"
                        id="invest"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        INVEST
                      </button>
                      <ul
                        class="dropdown-menu  position-absolute"
                        aria-labelledby="invest"
                      >
                        <li>
                          <Link
                            class="dropdown-item"
                            to="http://localhost:3000/blue-world-city-islamabad/"
                          >
                            Blue World City
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to="http://localhost:3000/blue-world-trade-center"
                          >
                            Blue World Trade Center{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to="http://localhost:3000/akbar-vista"
                          >
                            Akbar Vista
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to="http://localhost:3000/pearl-business-center"
                          >
                            Pearl Business Center
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to="http://localhost:3000/27-arclink"
                          >
                            27 ArchLink
                          </Link>
                        </li>
                        <li>
                          <Link
                            class="dropdown-item"
                            to="http://localhost:3000/24-karat-crown"
                          >
                            24 Karat Crown
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div>
                      <div
                        className="py-4 slider_home2 rounded-3 mt-3"
                        style={{ backgroundColor: "#8B8B88" }}
                      >
                        <div className="row px-5">
                          <div className="col-md-3">
                            <div>
                              {/* <select
                                class="form-select form-select-md mb-3 py-2"
                                aria-label=".form-select-md example"
                              > */}
                              <select
                                className="form-select form-select-md mb-3 py-2 rounded-3"
                                aria-label=".form-select-md example"
                                name="city"
                                id="city"
                                required
                                defaultValue={
                                  localStorage.getItem("searchcity") ||
                                  "Islamabad"
                                }
                              >
                                <option value="" disabled>
                                  Select City
                                </option>
                                <option value="Islamabad">Islamabad</option>

                                <option value="rawalpindi">Rawalpindi</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control py-2"
                              type="search"
                              placeholder="Search Property"
                              id="title"
                              defaultValue={localStorage.getItem("searchtitle")}
                            />
                          </div>
                          <div className="col-md-3">
                            <button
                              className="btn w-100 text-white px-5 py-2"
                              style={{
                                backgroundColor: "#4DB749",
                                opacity: "0.8",
                              }}
                              onClick={submitFilter}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                        <div
                          className="col-md-
                        12 ps-5 text-start"
                        >
                          <Link
                            to=""
                            className="ps-4 pt-4"
                            style={{ color: "#BBE2B0", textDecoration: "none" }}
                          >
                            + More Options
                          </Link>
                        </div>
                        <div className="px-5">
                          <div className="row pt-3">
                            <div className="col-md-3">
                              <div>
                                <select
                                  class="form-select form-select-md mb-3 py-2 rounded-3"
                                  aria-label=".form-select-md example"
                                  id="type"
                                  name="type"
                                  defaultValue={localStorage.getItem(
                                    "searchtype"
                                  )}
                                >
                                  <option selected>Any Type</option>
                                  <option value="house">House</option>
                                  <option value="flat">Flat</option>
                                  <option value="upperPortion">
                                    Upper Portion
                                  </option>
                                  <option value="lowerPortion">
                                    Lower Portion
                                  </option>
                                  <option value="room">Room</option>
                                  <option value="room">Plot</option>
                                  <option value="farmHouse">Farm House</option>
                                  <option value="pentHouse">Pent House</option>
                                  <option value="resedentialPlot">
                                    Resedential Plot
                                  </option>
                                  <option value="commercialPlot">
                                    Commercial Plot
                                  </option>
                                  <option value="agriculturalLand">
                                    Agricultural Land
                                  </option>
                                  <option value="industrialLand">
                                    Industrial Land
                                  </option>
                                  <option value="office">Office</option>
                                  <option value="shop">Shop</option>
                                  <option value="warehouse">WareHouse</option>
                                  <option value="factory">Factory</option>
                                  <option value="building">Building</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 position-relative rounded-container">
                              <div
                                className="bg-white rounded-container"
                                onClick={() => setPricePop(!pricePop)}
                              >
                                <input
                                  className="form-control p-0 bg-white border-0 px-3 rounded-3"
                                  type="text"
                                  placeholder="Search Price"
                                  disabled
                                />
                                <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3 ">
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {minPrice}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">to</p>
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {maxPrice}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">▼</p>
                                </div>
                              </div>

                              {pricePop ? (
                                <div className="rounded-2 bg-white p-2 d-flex flex-column my-2 price-popup">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h6 className="text-dark">MIN</h6>
                                      <select
                                        className="form-control"
                                        name="minPrice"
                                        value={minPrice}
                                        onChange={(e) =>
                                          setMinPrice(e.target.value)
                                        }
                                      >
                                        {priceOptions.map((price, index) => (
                                          <option key={index} value={price}>
                                            {price}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div>
                                      <h6 className="text-dark">MAX</h6>
                                      <select
                                        className="form-control"
                                        name="maxPrice"
                                        value={maxPrice}
                                        onChange={(e) =>
                                          setMaxPrice(e.target.value)
                                        }
                                      >
                                        {priceOptions.map((price, index) => (
                                          <option key={index} value={price}>
                                            {price}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary mt-2"
                                    onClick={() => setPricePop(false)}
                                  >
                                    Apply
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 position-relative rounded-container">
                              <div
                                className="bg-white rounded-container"
                                onClick={() => setBedPop(!bedPop)}
                              >
                                <input
                                  className="form-control p-0 bg-white border-0 px-3 rounded-3"
                                  type="text"
                                  placeholder="Search Area"
                                  disabled
                                />
                                <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3">
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {minArea} {areaUnit}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">to</p>
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {maxArea} {areaUnit}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">▼</p>
                                </div>
                              </div>

                              {bedPop ? (
                                <div className="rounded-2 bg-white p-2 d-flex flex-column my-2 price-popup">
                                  <div className="select-container">
                                    <div>
                                      <h6 className="text-dark">MIN</h6>
                                      <select
                                        className="form-control rounded-select"
                                        name="minArea"
                                        value={minArea}
                                        onChange={(e) =>
                                          setMinArea(e.target.value)
                                        }
                                      >
                                        {areaOptions.map((area, index) => (
                                          <option key={index} value={area}>
                                            {area}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div>
                                      <h6 className="text-dark">MAX</h6>
                                      <select
                                        className="form-control rounded-select"
                                        name="maxArea"
                                        value={maxArea}
                                        onChange={(e) =>
                                          setMaxArea(e.target.value)
                                        }
                                      >
                                        {areaOptions.map((area, index) => (
                                          <option key={index} value={area}>
                                            {area}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div>
                                      <h6 className="text-dark">UNIT</h6>
                                      <select
                                        className="form-control rounded-select"
                                        name="areaUnit"
                                        id="areaUnit"
                                        value={areaUnit}
                                        onChange={(e) =>
                                          setAreaUnit(e.target.value)
                                        }
                                      >
                                        {unitOptions.map((unit, index) => (
                                          <option
                                            key={index}
                                            value={unit.value}
                                          >
                                            {unit.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-primary mt-2"
                                    onClick={() => setBedPop(false)}
                                  >
                                    Apply
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 rounded-container">
                              <div className="rounded-container">
                                <select
                                  class="form-select form-select-md mb-3 py-2 rounded-container"
                                  aria-label=".form-select-md example"
                                  id="beds"
                                  name="beds"
                                  defaultValue={localStorage.getItem(
                                    "searchbeds"
                                  )}
                                >
                                  <option selected>All Beds</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="10+">10+</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <section className=" py-5">
        <div className="container">
          <h3>Properties</h3>
          <p>Found {myPost.length} Properties</p>
          <div className="row g-5 pt-5">
            <div className="col-md-3">
              <div className="row g-5">
                <div className="col-md-12">
                  <img
                    src={require("../../assets/images/bwc.jpeg")}
                    alt="ads"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div>
                <div>
                  <h6>
                    Sort By: <span>Default Sorting </span>
                  </h6>
                </div>
                <div></div>
              </div>
              <div className="row g-4">
                {myPost.length > 0 ? (
                  myPost.map((i) => {
                    const formattedTitle = formatTitle(i.title);
                    return (
                      <>
                        <div className="col-md-6">
                          <div className="position-relative">
                            <Link
                              to={`/property/${formattedTitle}/${i.id}`}
                              onClick={() => {
                                localStorage.removeItem("mysetprop");
                              }}
                              className="text-dark"
                            >
                              <div
                                className="  features    bg-white p-2"
                                style={{
                                  overflow: "hidden",
                                  borderTopLeftRadius: "15px",
                                  borderTopRightRadius: "15px",
                                  borderBottomLeftRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }}
                              >
                                <div className="">
                                  <div
                                    className="features_img"
                                    style={{
                                      overflow: "hidden",
                                      borderRadius: 12,
                                    }}
                                  >
                                    <img
                                      src={i?.image[0]}
                                      height="230px"
                                      width="100%"
                                      alt=""
                                    />
                                  </div>

                                  <div className="fire_logo">
                                    <img
                                      src={require("../../assets/images/fire.png")}
                                      alt=""
                                      width="30px"
                                    />
                                  </div>
                                  {/* <div className="heart_logo"  >
                            <img src={require('../../assets/images/heart.png')} alt="" width="30px" />
                          </div> */}
                                </div>
                                <div className="top px-2">
                                  <div className="d-flex pt-4 justify-content-between">
                                    <div>
                                      <h5 className="m-0">
                                        {i?.title
                                          ?.split(/\s+/)
                                          .slice(0, 5)
                                          .join(" ")}
                                        ...
                                      </h5>
                                      <span
                                        className=""
                                        style={{
                                          color: "#B9B9B9",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {" "}
                                        <MdLocationOn
                                          size={20}
                                          color="#4db749"
                                        />{" "}
                                        {i?.location
                                          ?.split(/\s+/)
                                          .slice(0, 5)
                                          .join(" ")}{" "}
                                        ...
                                      </span>
                                    </div>
                                    <div
                                      className="text-center"
                                      style={{
                                        position: "absolute",
                                        right: 15,
                                      }}
                                    >
                                      <img
                                        src={require("../../logo.png")}
                                        width="60px"
                                        alt=""
                                      />
                                      {/* <div>
                                <span className="m-0" style={{ fontSize: '10px' }}>New {i?.city} Estate  and <br />Properties  </span>
                              </div> */}
                                    </div>
                                    <div></div>
                                  </div>
                                  <div className="pt-3" style={{ gap: "30px" }}>
                                    <div className="d-flex align-items-center pb-2">
                                      {/* <img src={require('../../assets/images/header-contect icons/area.png')} alt="" width="20px" /> */}

                                      <FaChartArea color="#B9B9B9" size={20} />
                                      <h6
                                        className="ps-2 mb-0 text-bold"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Area {i?.size}
                                      </h6>
                                    </div>

                                    <div className="d-flex align-items-center">
                                      {/* 
                              <img src={require('../../assets/images/header-contect icons/Bedrooms.png')} alt="" width="20px" /> */}
                                      <IoIosBed color="#B9B9B9" size={20} />
                                      {/* <span className="ps-2" style={{ fontSize: '14px' }}></span> */}
                                      <h6
                                        className="mb-0 ps-2 text-black text-bold"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {i?.beds} Beds{" "}
                                      </h6>
                                    </div>
                                    <div className="d-flex align-items-center pt-2">
                                      {/* <img src={require('../../assets/images/header-contect icons/Bathrooms.png')} alt="" width="20px" /> */}
                                      <FaBath color="#B9B9B9" size={20} />
                                      <h6
                                        className="mb-0 ps-2 text-black text-bold"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {i?.baths} Baths
                                      </h6>
                                      {/* <span className="" style={{ fontSize: '14px' }}></span> */}
                                    </div>
                                  </div>
                                </div>
                                {/* <hr className="m-0" /> */}
                                <div className="footer px-2 pt-3 d-flex align-items-center justify-content-between">
                                  <div className="">
                                    <h5 className="house_price">
                                      PKR {i?.price}
                                    </h5>
                                  </div>
                                  <div
                                    className="d-flex ps-5"
                                    style={{ gap: "15px" }}
                                  >
                                    <div className="">
                                      <LuPhoneCall color="#4DB749" size={27} />
                                    </div>

                                    <div className="">
                                      <MdEmail color="#4DB749" size={27} />
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <h2>No Property Found</h2>
                )}
              </div>

              {/* <nav className="pt-4" aria-label="...">
                <ul class="pagination pagination-lg justify-content-center">
                  <li class="page-item "><a class="page-link" href="#">1</a></li>
                  <li class="page-item rounded-2 active" aria-current="page">
                    <span class="page-link">2</span>
                  </li>
                  <li class="page-item">
                    <span class="page-link">3</span>
                  </li>

                  <li class="page-item  pt-4" style={{ fontSize: '22px' }}>. . . .</li>

                  <li class="page-item  align-items-center d-flex justify-content-center">
                    <a class="" href="#"><i><TfiAngleRight color='#4DB749' size={40} /></i></a>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AllProperties;
