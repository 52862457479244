import React, { useEffect, useState } from "react";
import { BsQuestionLg } from "react-icons/bs";
import api_url from "../../ApiUrl";
import axios from "axios";
const AskedQuestion = () => {
  const [myPost, setMyPost] = useState([]);

  useEffect(() => {
    axios.get(api_url + `question/get`).then((res) => {
      console.log(res.data, "==========>img");
      setMyPost(res.data.data);
    });
  }, []);

  return (
    <>
      <div className=" container mt-5 mb-5">
        <div className="text-center">
          <h2
            style={{
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "28.8px",
            }}
          >
            FREQUENTY ASKED QUESTIONS
          </h2>
          <img src={require("./../../img/arrow.png")} />
        </div>
        <br />

        <div className="row">
          <div className="col-md-12">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      What is Makkaan.com?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Makkaan.com is a real estate property portal that connects
                    buyers, renters, and property owners to facilitate seamless
                    property transactions.
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      How does Makkaan.com work?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Makkaan.com works by providing a user-friendly interface
                    where individuals can search for properties using filters,
                    detailed property listings, and a direct communication
                    channel between buyers and sellers.
                  </div>
                </div>
              </div>
              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      What services does Makkaan.com offer?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Makkaan.com provides 360-degree marketing solutions and
                    transparent projects to its end-users and offers the
                    services of buying, selling, and property listings.
                  </div>
                </div>
              </div>
              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      How is Makkaan.com helpful for property owners?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Property owners can add their properties to the platform and
                    gain exposure to a wide audience of potential buyers or
                    tenants
                  </div>
                </div>
              </div>
              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      How can I search for properties on Makkaan.com?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    To search for properties on Makkaan.com, simply visit our
                    website and use the search bar. Enter the desired location,
                    property type, price range, and any other relevant filters.
                    The portal will display a list of properties that match your
                    search criteria.
                  </div>
                </div>
              </div>
              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      How can I add my property on Makkaan.com?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Adding your property on Makkaan.com is easy. Simply create
                    an account on our website and follow the instructions to
                    list your property. Provide accurate details, high-quality
                    photos, and any additional information that can help attract
                    potential buyers or tenants.
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingEight">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      Is Makkaan.com free to use for property buyers and
                      renters?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Yes, Makkaan.com is absolutely free for property buyers and
                    renters. You can search and explore properties without any
                    charges. We are dedicated to making the property search
                    process as convenient and accessible as possible.
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingnine">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsenine"
                    aria-expanded="false"
                    aria-controls="collapsenine"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      How does Makkaan.com ensure the authenticity and
                      reliability of property listings?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapsenine"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingnine"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Makkaan.com strives to maintain the authenticity and
                    reliability of property listings. We encourage property
                    owners to provide accurate information and verify their
                    listings.
                  </div>
                </div>
              </div>
              <div class="accordion-item mt-2">
                <h2 class="accordion-header" id="headingten">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseten"
                    aria-expanded="false"
                    aria-controls="collapseten"
                  >
                    <b
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21.6px",
                      }}
                    >
                      Is there any customer support available for assistance?
                    </b>
                  </button>
                </h2>
                <div
                  id="collapseten"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingten"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.4px",
                    }}
                  >
                    Yes, we have a dedicated customer support team available to
                    assist you with any queries or issues you may have. You can
                    reach out to our customer support through the contact
                    information provided on our website, and they will be happy
                    to assist you.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AskedQuestion;
