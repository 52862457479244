import React from "react";
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import Footer from "../components/footer/Footer";

function Policypage() {
  return (
    <div>
      <TopNav />
      <BottomNav />

      <div class="container my-5">
        <h1 class="mb-4">Privacy Policy of Makkaan.com</h1>

        <p>
          At Makkaan.com, we value the privacy of our users and are committed to
          protecting their personal information. This Privacy Policy outlines
          how we collect, use, disclose, and protect the data we collect from
          users of our website and any related services. By accessing or using
          our Website, you agree to the terms and practices described in this
          policy.
        </p>

        <h3>Information We Collect</h3>
        <ul>
          <li>
            <strong>Personal Information:</strong> This includes, but is not
            limited to, your name, email address, phone number, postal address,
            and other information provided voluntarily during registration,
            inquiries, or while using our services.
          </li>
          <li>
            <strong>Non-Personal Information:</strong> We may automatically
            collect certain non-personal information about your use of our
            Website, such as your IP address, browser type, device information,
            and cookies to enhance your browsing experience and improve our
            services.
          </li>
        </ul>

        <h3>How We Use Information</h3>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>
            <b>Providing Services:</b> To facilitate your access to and use of
            our Website and to provide the services you request.
          </li>
          <li>
            <b> Communication:</b> To respond to your inquiries, provide
            customer support, and send you important updates and notifications
            related to our services.
          </li>
          <li>
            <b>Personalization: </b> To personalize and improve your experience
            on our Website, including providing relevant content and
            recommendations.
          </li>
          <li>
            <b>Marketing:</b> To send you promotional materials and offers,
            which you can opt out of at any time.
          </li>
          <li>
            <b> Analytics: </b>To analyze user behavior, preferences, and trends
            to enhance our services and user experience.
          </li>
        </ul>

        <h3>Data Sharing with Third Parties</h3>
        <p>
          We may share your information with third parties under the following
          circumstances:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may engage trusted
            third-party service providers to assist us in providing the Website
            and related services. These providers have access to your
            information only to perform specific tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may share your information if
            required by law or in response to valid legal requests, such as a
            court order or government inquiry.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your
            information may be transferred to the acquiring entity.
          </li>
        </ul>

        <h3>Third-Party Services</h3>
        <p>
          We engage third-party services to display advertisements on our
          website, and these third parties may employ cookies or other methods
          to assess the impact of their ads. Additionally, our website may
          contain links to unrelated external websites and contact forms
          associated with other unrelated parties that might gather personal
          information.
        </p>
        <p>
          Please note that our privacy policy does not apply to these third
          parties or external websites, and we advise users to review their
          respective privacy policies before providing any personal details to
          these entities.
        </p>

        <h3>Safety and Security</h3>
        <p>
          We process and store personal information in our databases,
          implementing reasonable measures to safeguard and protect the data
          from unauthorized access or disclosure. Despite our best efforts to
          ensure security, we cannot guarantee absolute protection against
          misuse, accidental disclosure, or unauthorized actions by third
          parties. Please be aware that the information you provide may be
          stored or processed outside Pakistan.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p>
          <strong>UAN:</strong> +923311111049
        </p>
        <p>
          <strong>Email:</strong> info@makkaan.com
        </p>

        <h3>Changes to Our Privacy Policy</h3>
        <p>
          Any modifications or revisions to our privacy policy in the future
          will be published on this page, and if necessary, we will inform you
          via email. We encourage you to visit this page regularly to stay
          informed about any updates or adjustments made to our privacy policy.
        </p>

        <p>
          By using Makkaan.com, you consent to the collection and use of your
          information as described in this Privacy Policy.
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Policypage;
