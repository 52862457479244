import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api_url from "../../ApiUrl";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Developers() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const [myPost, setMyPost] = useState([]);

  useEffect(() => {
    axios.get(api_url + `user/get`).then((res) => {
      const allUsers = res.data.data;
      const developerUsers = allUsers.filter(
        (user) => user.signAs === "developer"
      );
      setMyPost(developerUsers);
    });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center mb-5">
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "28.8px",
                }}
              >
                PLATINUM DEVELOPERS
              </h2>
              <img src={require("../../img/arrow.png")} alt="Arrow" />
            </div>

            <section className="container-fluid">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={2000}
                infinite={true}
                itemClass="carousel-item-padding-40-px"
              >
                {myPost.map((developer, index) => (
                  <div className="" key={index}>
                    <Link to={`/developerdetails/${developer?.id}`}>
                      <div className="card d-flex flex-column p-3 h-100 text-center">
                        <img
                          src={developer.agencyLogo}
                          className="card-img-top mx-auto"
                          alt="Developer"
                          style={{
                            width: "30%",
                            height: "30%",
                            objectFit: "cover",
                          }}
                        />
                        <div className="card-body d-flex flex-column flex-grow-1">
                          <h5
                            style={{ color: "black", fontSize: "12px" }}
                            className="card-title"
                          >
                            {developer.agencyName}
                          </h5>
                          <p className="card-text">{developer.serviceArea}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Developers;
