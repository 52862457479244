import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import axios from "axios";
import api_url from "../ApiUrl";
import "../components/Propertyslider.css";
import { SampleNextArrow, SamplePrevArrow } from "../makkaanpages/CustomArrow";

function Propertyslider() {
  const { id } = useParams();
  const [myPost, setMyPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`${api_url}property/get/${id}`)
      .then((res) => {
        console.log(res.data, "==========>img");
        setMyPost(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  if (!myPost || !myPost.image || myPost.image.length === 0) {
    return <div>No images to display</div>;
  }

  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: myPost.image.length > 1, // Infinite loop only if more than one image
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return (
        <a>
          <img src={myPost.image[i]} alt={`Thumbnail ${i}`} />
        </a>
      );
    },
  };

  return (
    <div>
      <div className="slider-container">
        <Slider {...settings}>
          {myPost.image.map((imgSrc, index) => (
            <div key={index}>
              <img
                src={imgSrc}
                alt={`Property Image ${index}`}
                style={{
                  width: "100%",
                  height: "480px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Propertyslider;
