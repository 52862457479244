import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import api_url from "../../ApiUrl";
import axios from "axios";
import { useParams } from "react-router-dom";
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";

import { IoIosBed } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { FaChartArea, FaBath } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";

const DeveloperDetails = () => {
  const { id } = useParams();

  const [myPost, setMyPost] = useState([]);
  const [developerDetails, setDeveloperDetails] = useState({});
  const [propertyLength, setPropertyLength] = useState(0);
  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    axios
      .get(`${api_url}user/get/${id}`)
      .then((res) => {
        console.log(
          res.data,
          "Agency details data==================================="
        );
        setMyPost(res.data?.data?.property || []);
        setDeveloperDetails(res.data?.data || {});

        if (res.data?.data?.property) {
          setPropertyLength(res.data.data.property.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [api_url, id]);

  useEffect(() => {
    console.log(myPost, "my Agency details data");
  }, [myPost]);

  useEffect(() => {
    axios
      .get(`${api_url}staff/get/${id}`)
      .then((res) => {
        console.log(res.data, "API response"); // Log to inspect the structure
        setStaffData(res.data.data || []); // Access the "data" property that contains the array
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id, api_url]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <TopNav />
      <BottomNav style={{}} />
      <div
        id="intro-example"
        class="p-5 text-center bg-image"
        style={{
          backgroundImage: `url('${require("./../../img/banner/developer.png")}')`,
          height: "170px",
        }}
      ></div>
      <br />
      <br />
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12" style={{ padding: "5px" }}>
                <div class="card mb-3 p-3">
                  <div class="row p-2">
                    <div class="col-md-2">
                      <div
                        style={{
                          width: "100%",
                          height: "120px",
                          backgroundImage: `url(${developerDetails?.agencyLogo})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                        className="img-fluid rounded-start"
                      ></div>
                    </div>
                    <div class="col-md-7">
                      <h5
                        className="text-muted"
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                          lineHeight: "20px",
                        }}
                      >
                        {developerDetails?.agencyName &&
                          developerDetails.agencyName.charAt(0).toUpperCase() +
                            developerDetails.agencyName.slice(1)}
                      </h5>
                      <span
                        class="text-muted"
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "15.6px",
                        }}
                      >
                        {developerDetails?.city}
                      </span>
                      <br />
                      <span
                        class="text-muted"
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15.6px",
                        }}
                      >
                        {propertyLength} Properties Listed
                      </span>
                    </div>
                    <div class="col-md-3">
                      <br />
                      <br />

                      <a
                        href=""
                        class="btn  btn-outline-success rounded-pill p-2"
                        style={{
                          fontWeight: "600",
                          fontSize: "13px",
                          lineHeight: "15.6px",
                          float: "right",
                        }}
                      >
                        <i class="fas fa-share-nodes text-dark"></i>{" "}
                        <span className="text-dark"> Share</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div
                        class="p-3 "
                        style={{
                          backgroundColor: "#4DB74940",
                          width: "5.5%",
                          borderRadius: "100%",
                          float: "left",
                        }}
                      >
                        <i class="fas fa-house-chimney-crack bg_color_maken"></i>
                      </div>
                      <h5
                        class="p-3"
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19.2px",
                        }}
                      >
                        &nbsp; <b>{propertyLength}&nbsp; Properties For Sale</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <span
                          class="bg_color_maken"
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "15.6px",
                          }}
                        >
                          view all{" "}
                          <i class="fas fa-arrow-up-right-from-square"></i>{" "}
                        </span> */}
                      </h5>
                    </div>
                    <div class="card-body">
                      <b>
                        About{" "}
                        {developerDetails?.agencyName &&
                          developerDetails.agencyName.charAt(0).toUpperCase() +
                            developerDetails.agencyName.slice(1)}
                      </b>

                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "25.6px",
                        }}
                      >
                        {developerDetails?.agencyDescription &&
                          developerDetails.agencyDescription
                            .charAt(0)
                            .toUpperCase() +
                            developerDetails.agencyDescription.slice(1)}
                      </p>

                      <h5
                        style={{
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "24px",
                        }}
                      >
                        <b>Agency Staff</b>
                      </h5>
                      <br />

                      <div>
                        {staffData.length > 0 ? (
                          <Carousel responsive={responsive}>
                            {staffData.map((staff, index) => (
                              <div key={index} className="card">
                                <div
                                  className="card-body text-center"
                                  style={{
                                    backgroundImage: `url('${require("./../../img/image 5.png")}')`,
                                  }}
                                >
                                  <img
                                    src={require("./../../img/image 4.png")}
                                    className="img-fluid rounded-circle"
                                    alt="Staff"
                                    style={{ width: "40%", height: "100px" }}
                                  />
                                  <hr />
                                  <h5
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      lineHeight: "19.2px",
                                    }}
                                  >
                                    {staff.fullname}
                                  </h5>
                                  <a
                                    href={`tel:${staff.phone}`}
                                    className="btn bg_makkan text-white"
                                    style={{
                                      backgroundColor: "#4DB749",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <i className="fas fa-phone-flip"></i> Call
                                  </a>
                                  <a
                                    href={`mailto:${staff.email}`}
                                    className="btn btn-outline-success"
                                  >
                                    <i className="far fa-envelope"></i> Mail
                                  </a>
                                </div>
                              </div>
                            ))}
                          </Carousel>
                        ) : (
                          <p>No staff data available</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div className="card p-3">
              <h5
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                Get in touch today!
              </h5>
              <span
                className="text-muted"
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15.6px",
                }}
              >
                send a message and get in touch today.
              </span>
              <br />
              <div className="d-flex align-items-center">
                <div className="ms-3">
                  <button
                    type="button"
                    class="btn btn-dark btn-lg btn-block w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      class="fas fa-phone text-dark blink"
                    ></i>
                    Call Now
                  </button>

                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="staticBackdropLabel">
                            Contact Details
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <h5>Phone Number</h5>
                          <p>Pakistan</p>
                          <a
                            href={`tel:${developerDetails?.phone}`}
                            className="text-decoration-none"
                          >
                            <i
                              className="fas fa-phone"
                              style={{ marginRight: "15px" }}
                            ></i>
                            {developerDetails?.phone}{" "}
                          </a>
                        </div>
                        <div class="modal-footer">
                          <button
                            onClick={() =>
                              navigator.clipboard.writeText(
                                developerDetails?.phone
                              )
                            }
                            type="button"
                            class="btn btn-light"
                            data-bs-dismiss="modal"
                          >
                            <i
                              style={{ marginRight: "15px" }}
                              className="fas fa-copy"
                            ></i>{" "}
                            Copy Number
                          </button>
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-d-12 mt-3">
                  <div data-mdb-input-init className="form-outline">
                    <input
                      type="text"
                      id="form8Example1"
                      className="form-control border-bottom"
                    />
                    <label
                      className="form-label"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                      }}
                      for="form8Example1"
                    >
                      Your Name
                    </label>
                  </div>
                </div>
                <div className="col-d-12 mt-3">
                  <div data-mdb-input-init className="form-outline">
                    <input
                      type="email"
                      id="form8Example2"
                      className="form-control border-bottom"
                    />
                    <label
                      className="form-label"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                      }}
                      for="form8Example2"
                    >
                      Phone Number
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-d-12 mt-3">
                  <div data-mdb-input-init className="form-outline">
                    <input
                      type="text"
                      id="form8Example3"
                      className="form-control border-bottom"
                    />
                    <label
                      className="form-label"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                      }}
                      for="form8Example3"
                    >
                      Email Address
                    </label>
                  </div>
                </div>
                <div className="col-d-12 mt-3">
                  <div data-mdb-input-init className="form-outline">
                    <input
                      type="text"
                      id="form8Example4"
                      className="form-control border-bottom"
                    />
                    <label
                      className="form-label"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                      }}
                      for="form8Example4"
                    >
                      Subject
                    </label>
                  </div>
                </div>
                <div className="col-d-12 mt-3">
                  <div data-mdb-input-init className="form-outline">
                    <input
                      type="email"
                      id="form8Example5"
                      className="form-control border-bottom"
                    />
                    <label
                      className="form-label"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                      }}
                      for="form8Example5"
                    >
                      Your Message
                    </label>
                  </div>
                </div>
              </div>
              <a
                href=""
                className="btn bg_makkan text-white w-100 mt-3"
                style={{ backgroundColor: "#4DB749" }}
              >
                SEND YOUR QUESTION
              </a>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15.6px",
                  marginTop: "20px",
                }}
              >
                By submited this form, agree to Terms of Use.
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div
        className="container-fluid w-100 p-5"
        style={{
          backgroundImage: `url('${require("./../../img/featurebg.png")}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div class="">
          <h2
            style={{
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            Recent Properties By &nbsp;
            {developerDetails?.agencyName &&
              developerDetails.agencyName.charAt(0).toUpperCase() +
                developerDetails.agencyName.slice(1)}
          </h2>
        </div>
        {myPost.length > 0 ? (
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite={true}
            itemClass="carousel-item-padding-40-px"
          >
            {myPost.map((property) => (
              <div
                className="container-fluid"
                style={{ marginBottom: "10px" }}
                key={property.id}
              >
                <div className="position-relative">
                  <Link
                    to={`/view_property/${property.id}`}
                    onClick={() => {
                      localStorage.removeItem("mysetprop");
                    }}
                    className="text-dark"
                  >
                    <div
                      className="features bg-white p-2"
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        height: "435px", // Set a fixed height for the card
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          className="features_img"
                          style={{
                            overflow: "hidden",
                            borderRadius: 12,
                          }}
                        >
                          <img
                            src={property.image[0]}
                            height="230px"
                            width="100%"
                            alt=""
                          />
                        </div>

                        <div className="fire_logo">
                          <img
                            src={require("../../assets/images/fire.png")}
                            alt=""
                            width="30px"
                          />
                        </div>
                      </div>
                      <div className="top px-2">
                        <div className="d-flex pt-4 justify-content-between">
                          <div>
                            {property.advancePayment &&
                              property.installments &&
                              property.monthlyInstallments && (
                                <span
                                  className="badge rounded-pill mb-3"
                                  style={{ backgroundColor: "#4db749" }}
                                >
                                  On Installments
                                </span>
                              )}
                            <h5
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              {property.title
                                ?.split(/\s+/)
                                .slice(0, 5)
                                .join(" ")}
                              ...
                            </h5>
                            <span
                              className=""
                              style={{
                                color: "#B9B9B9",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              <MdLocationOn size={20} color="#4db749" />
                              {property.location
                                ?.split(/\s+/)
                                .slice(0, 4)
                                .join(" ")}
                              ...
                            </span>
                          </div>
                          <div
                            className="text-center"
                            style={{
                              position: "absolute",
                              right: 15,
                            }}
                          >
                            <img
                              src={property.agencyLogo}
                              width="60px"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="pt-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <FaChartArea color="#B9B9B9" size={20} />
                            <h6
                              className="ps-2 mb-0 text-bold"
                              style={{ fontSize: "14px" }}
                            >
                              Area {property.size}
                            </h6>
                          </div>
                          {property?.beds && (
                            <div className="d-flex align-items-center">
                              <IoIosBed color="#B9B9B9" size={20} />
                              <h6
                                className="mb-0 ps-2 text-black text-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {property.beds} Beds
                              </h6>
                            </div>
                          )}
                          {property?.baths && (
                            <div className="d-flex align-items-center">
                              <FaBath color="#B9B9B9" size={20} />
                              <h6
                                className="mb-0 ps-2 text-black text-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {property.baths} Baths
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="footer px-2 pt-3 d-flex align-items-center justify-content-between">
                        <div className="">
                          <h5 className="house_price">PKR: {property.price}</h5>
                        </div>
                        <div className="d-flex ps-5" style={{ gap: "15px" }}>
                          <a href={`tel:${property.phone}`}>
                            <div className="">
                              <LuPhoneCall color="#4DB749" size={27} />
                            </div>
                          </a>
                          <a href={`mailto:${property.email}`}>
                            <div className="">
                              <MdEmail color="#4DB749" size={27} />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </Carousel>
        ) : null}
      </div>

      <Footer />
    </>
  );
};

export default DeveloperDetails;
