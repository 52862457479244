import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import api_url from "../ApiUrl";

function Agencystaff() {
  // State variables for form inputs and staff data
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [staffList, setStaffList] = useState([]); // State to store staff data
  const myId = localStorage.getItem("mainUserId");

  const userId = localStorage.getItem("mainUserId");
  useEffect(() => {
    axios
      .get(`${api_url}staff/get/${userId}`)
      .then((response) => {
        if (response.data.status === "ok") {
          setStaffList(response.data.data); // Update state with staff data
        }
      })
      .catch((error) => {
        console.error("Error fetching staff data:", error);
      });
  }, [api_url, userId]); // Dependency array ensures the effect runs on component mount and when api_url or userId changes

  // Handle form submission
  const submit = async (event) => {
    event.preventDefault();
    if (!myId) {
      toast.error("Your Agent staff");
    } else if (fullname.trim().length === 0) {
      toast.error("Must enter name");
    } else if (phone.trim().length === 0) {
      toast.error("Must enter phone");
    } else if (email.trim().length === 0) {
      toast.error("Must enter email");
    } else {
      const params = {
        fullname: fullname.trim(),
        email: email.trim(),
        phone: phone.trim(),
        userId: myId,
      };

      try {
        const res = await axios.post(`${api_url}staff/create`, params);
        if (res.data.status === "ok") {
          toast.success("Registered successfully!");
          setFullname("");
          setEmail("");
          setPhone("");
          setStaffList((prevStaffList) => [...prevStaffList, res.data.data]);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  // Handle delete staff
  const deleteStaff = async (id) => {
    if (window.confirm("Are you sure you want to delete this staff member?")) {
      try {
        const res = await axios.delete(`${api_url}staff/delete/${id}`);
        if (res.data.status === "ok") {
          toast.success("Staff member deleted successfully!");
          setStaffList((prevStaffList) =>
            prevStaffList.filter((staff) => staff.id !== id)
          );
        } else {
          toast.error("Failed to delete staff member.");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the staff member.");
      }
    }
  };

  // Handle edit staff
  const handleEdit = (staff) => {
    setFullname(staff.fullname);
    setEmail(staff.email);
    setPhone(staff.phone);
    setEditId(staff.id);
    setEditing(true);
  };

  // Handle update staff
  const updateStaff = async (event) => {
    event.preventDefault();
    if (!myId) {
      toast.error("Your Agent staff");
    } else if (fullname.trim().length === 0) {
      toast.error("Must enter name");
    } else if (phone.trim().length === 0) {
      toast.error("Must enter phone");
    } else if (email.trim().length === 0) {
      toast.error("Must enter email");
    } else {
      const params = {
        fullname: fullname.trim(),
        email: email.trim(),
        phone: phone.trim(),
        userId: myId,
      };

      try {
        const res = await axios.put(`${api_url}staff/update/${editId}`, params);
        if (res.data.status === "ok") {
          toast.success("Staff member updated successfully!");
          setStaffList((prevStaffList) =>
            prevStaffList.map((staff) =>
              staff.id === editId ? { ...staff, ...params } : staff
            )
          );
          resetForm();
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  // Reset form
  const resetForm = () => {
    setFullname("");
    setEmail("");
    setPhone("");
    setEditing(false);
    setEditId(null);
  };

  return (
    <div style={{ padding: "2em" }}>
      <ToastContainer />
      <h3 className="text-dark" style={{ marginTop: "2em" }}>
        Agency Staff List
      </h3>

      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                {editing ? "Edit Staff Details" : "Add Staff Details"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetForm}
              ></button>
            </div>

            <div className="modal-body">
              <div className="container-fluid">
                <form onSubmit={editing ? updateStaff : submit}>
                  <div className="mb-2">
                    <label htmlFor="fullname" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="fullname"
                      name="fullname"
                      placeholder="Write Full Name"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>
                  <div className="pt-2">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="email"
                      placeholder="email@.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="pt-2">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="phone"
                      placeholder="+923-0000000"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary" type="submit">
                      {editing ? "Update" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        className="btn btn-primary"
        data-bs-toggle="modal"
        href="#exampleModalToggle"
        role="button"
        onClick={resetForm}
      >
        Add Agency Staff
      </a>

      <table className="table" style={{ marginTop: "2em" }}>
        <thead className="table-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {staffList.length > 0 ? (
            staffList.map((staff, index) => (
              <tr key={staff.id}>
                <th scope="row">{index + 1}</th>
                <td>{staff.fullname}</td>
                <td>{staff.phone}</td>
                <td>{staff.email}</td>
                <td>
                  <i
                    className="fa fa-trash"
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    onClick={() => deleteStaff(staff.id)}
                  ></i>
                  <i
                    className="fa fa-edit"
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalToggle"
                    onClick={() => handleEdit(staff)}
                  ></i>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No staff found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Agencystaff;
