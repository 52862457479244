import axios from "axios";
import React from "react";
import api_url from "../../ApiUrl";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import background from "./../../img/footer.png";
import logo from "./../../img/footerlogo.png";
const Footer = () => {
  const submit = () => {
    if (document.getElementById("mail").value.length === 0) {
      toast.error("Must enter a valid email address");
    } else {
      axios
        .post(`${api_url}subscribe/create`, {
          email: document.getElementById("mail").value,
        })
        .then((res) => {
          if (res.data.status === "ok") {
            toast.success("Subscribe successfully.");
          }
        });
    }
  };

  return (
    <>
      <footer
        class="text-center text-lg-start bg-body-tertiary text-muted"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
        }}
      >
        <section
          class="p-3 text-white"
          style={{ backgroundColor: `rgba(0,0,0,0.8)` }}
        >
          <div class="container text-center text-md-start mt-5">
            <div class="row mt-3">
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <div class="mb-4">
                  <img src={logo} />
                </div>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "1.5em",
                    color: "white",
                    fontFamily: "lato",
                  }}
                >
                  2nd Floor Jackson Heights GT RD, Rawalpindi, 46000
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "1.5em",
                    color: "white",
                    fontFamily: "lato",
                  }}
                >
                  <i class="fas fa-envelope me-3"></i>
                  info@makkaan.com
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "1.5em",
                    color: "white",
                    fontFamily: "lato",
                  }}
                >
                  <i class="fas fa-phone me-3"></i> + 92 331 111 10 49
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "1.5em",
                    color: "white",
                    fontFamily: "lato",
                  }}
                >
                  <i class="fas fa-print me-3"></i> + 92 331 111 10 49
                </p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6
                  className="text-uppercase fw-bold mb-4"
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  QUICK LINKS
                </h6>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  <li style={{ marginBottom: "10px" }}>
                    <Link
                      className="text-reset"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "32px",
                      }}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link
                      className="text-reset"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "32px",
                      }}
                      to="/about_us"
                    >
                      About
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link
                      className="text-reset"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "32px",
                        color: "white",
                      }}
                      to="/blogs"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link
                      to="/privacy-policy"
                      className="text-reset"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "1.5em",
                        color: "white",
                        fontFamily: "lato",
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link
                      to="/term-and-condition"
                      className="text-reset"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "1.5em",
                        color: "white",
                        fontFamily: "lato",
                      }}
                    >
                      Terms And Condition
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <Link
                      to="/contact"
                      className="text-reset"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "1.5em",
                        color: "white",
                        fontFamily: "lato",
                      }}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6
                  class="text-uppercase fw-bold mb-4"
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "1.5em",
                    color: "white",
                    fontFamily: "lato",
                  }}
                >
                  TIMINGS
                </h6>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "1.5em",
                    color: "white",
                    fontFamily: "lato",
                  }}
                >
                  10AM to 6PM Monday to Saturday
                </p>
              </div>

              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <br />
                <h6
                  class="text-uppercase fw-bold mb-4"
                  style={{
                    fontWeight: "600",
                    fontSize: "19.4px",
                    lineHeight: "23.8px",
                  }}
                >
                  FOLLOW US ON
                </h6>

                <div>
                  <a
                    href="https://www.facebook.com/makkaan.com.pk/"
                    class="me-4 bg_color_maken"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://x.com/makkaan_dotcom"
                    class="me-4 bg_color_maken"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a href="" class="me-4 bg_color_maken">
                    <i class="fab fa-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/makkaanofficial/"
                    class="me-4 bg_color_maken"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/makkaanofficial/"
                    class="me-4 bg_color_maken"
                  >
                    <i class="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="text-center p-4 "
          style={{ backgroundColor: `rgba(0, 0, 0, 0.9)`, color: "white" }}
        >
          © 2024 Copyright &nbsp;
          <a class="text-reset fw-bold" href="#">
            Makkaan.com
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
