import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import api_url from "../../ApiUrl";
import { Link } from "react-router-dom";
import { IoIosBed } from "react-icons/io";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { FaChartArea, FaBath } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";

const Relatedproject = ({ userId }) => {
  const [relatedProperties, setRelatedProperties] = useState([]);

  useEffect(() => {
    if (userId) {
      axios.get(`${api_url}property/get`).then((res) => {
        const filteredData = res.data.data.filter(
          (property) => property.userId === userId
        );
        setRelatedProperties(filteredData);
      });
    }
  }, [userId]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const formatTitle = (title) => {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, ""); // Remove special characters
  };

  return (
    <div
      className="container-fluid w-100 mt-2 mb-2"
      style={{
        backgroundImage: `url('${require("./../../img/featurebg.png")}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="container-fluid mb-4 mt-4 mx-4">
        <h4 style={{ fontWeight: "bold" }}>Related Property</h4>
      </div>
      {relatedProperties.length > 0 && (
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          itemClass="carousel-item-padding-40-px"
        >
          {relatedProperties.map((property) => (
            <div
              className="container-fluid"
              style={{ marginBottom: "10px" }}
              key={property.id}
            >
              <div className="position-relative">
                <Link
                  to={`/property/${formatTitle(property.title)}/${property.id}`}
                  onClick={() => {
                    localStorage.removeItem("mysetprop");
                  }}
                  className="text-dark"
                >
                  <div
                    className="features bg-white p-2"
                    style={{
                      overflow: "hidden",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      height: "435px", // Set a fixed height for the card
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        className="features_img"
                        style={{
                          overflow: "hidden",
                          borderRadius: 12,
                        }}
                      >
                        <img
                          src={property.image[0]}
                          height="230px"
                          width="100%"
                          alt=""
                        />
                      </div>

                      <div className="fire_logo">
                        <img
                          src={require("../../assets/images/fire.png")}
                          alt=""
                          width="30px"
                        />
                      </div>
                    </div>
                    <div className="top px-2">
                      <div className="d-flex pt-4 justify-content-between">
                        <div>
                          {property.advancePayment &&
                            property.installments &&
                            property.monthlyInstallments && (
                              <span
                                className="badge rounded-pill mb-3"
                                style={{ backgroundColor: "#4db749" }}
                              >
                                On Installments
                              </span>
                            )}
                          <h5
                            className="m-0"
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {property.title?.split(/\s+/).slice(0, 5).join(" ")}
                            ...
                          </h5>
                          <span
                            className=""
                            style={{
                              color: "#B9B9B9",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            <MdLocationOn size={20} color="#4db749" />
                            {property.location
                              ?.split(/\s+/)
                              .slice(0, 4)
                              .join(" ")}
                            ...
                          </span>
                        </div>
                        <div
                          className="text-center"
                          style={{
                            position: "absolute",
                            right: 15,
                          }}
                        >
                          <img src={property.agencyLogo} width="60px" alt="" />
                        </div>
                      </div>
                      <div className="pt-3 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <FaChartArea color="#B9B9B9" size={20} />
                          <h6
                            className="ps-2 mb-0 text-bold"
                            style={{ fontSize: "14px" }}
                          >
                            Area {property.size}
                          </h6>
                        </div>
                        {property?.beds && (
                          <div className="d-flex align-items-center">
                            <IoIosBed color="#B9B9B9" size={20} />
                            <h6
                              className="mb-0 ps-2 text-black text-bold"
                              style={{ fontSize: "14px" }}
                            >
                              {property.beds} Beds
                            </h6>
                          </div>
                        )}
                        {property?.baths && (
                          <div className="d-flex align-items-center">
                            <FaBath color="#B9B9B9" size={20} />
                            <h6
                              className="mb-0 ps-2 text-black text-bold"
                              style={{ fontSize: "14px" }}
                            >
                              {property.baths} Baths
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="footer px-2 pt-3 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h5 className="house_price">PKR: {property.price}</h5>
                      </div>
                      <div className="d-flex ps-5" style={{ gap: "15px" }}>
                        <a href={`tel:${property.phone}`}>
                          <div className="">
                            <LuPhoneCall color="#4DB749" size={27} />
                          </div>
                        </a>
                        <a href={`mailto:${property.email}`}>
                          <div className="">
                            <MdEmail color="#4DB749" size={27} />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default Relatedproject;
