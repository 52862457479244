import React, { useEffect, useState } from "react";

import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";
import Blogss from "../../components/blogs/Blogs";
import api_url from "../../ApiUrl";
import axios from "axios";
import background from "./../../img/banner/blogs.png";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [myPost, setMyPost] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(api_url + `blog/get`).then((res) => {
      console.log(res.data.data, "==========>img");
      setMyPost(res.data.data);
    });
  }, []);

  return (
    <>
      <TopNav />
      <BottomNav />

      <section classNameName="about_us">
        <div
          id="intro-example"
          class="p-5 text-center bg-image"
          style={{ backgroundImage: `url(${background})`, height: "150px" }}
        ></div>
      </section>

      <div className="container mt-5 mb-5">
        <div className="text-center">
          <h2
            style={{
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "28.6px",
            }}
          >
            LASTEST BLOGS & POST
          </h2>

          <img src={require("./../../img/arrow.png")} />

          <Blogss />
        </div>
        <br />
      </div>

      <Footer />
    </>
  );
};

export default Blogs;
