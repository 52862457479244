import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import Footer from "../components/footer/Footer";
import { ToastContainer } from "react-toastify";
import { MdCall, MdEmail } from "react-icons/md";

import B1 from "../makkaanpages/images/Artboard 1.jpg";
import B2 from "../makkaanpages/images/Artboard 1-1.jpg";
import B3 from "../makkaanpages/images/BWC3.png";

import karat1 from "../makkaanpages/images/9K Nathiagali short payment plan.png";
import karat2 from "../makkaanpages/images/9k nathiagali floorplan.webp";
import developerlogo from "../makkaanpages/images/9ksuiteslogo.png";
import makkaanLogo from "../makkaanpages/images/makkaanlogo.png";

import paymentplan from "../makkaanpages/pdf/9knathiagali-floorplan.pdf";
import floorplan from "../makkaanpages/pdf/9knathiagali-floorplan.pdf";

import Slider from "react-slick";
import ReactPlayer from "react-player";
import "./CustomArrow.css";
import "../App.css";

import { SampleNextArrow, SamplePrevArrow } from "./CustomArrow";

function Nathiagali() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const stages = [
    { id: "media1", label: "May 2024", media: B1 },
    // { id: "media2", label: "Jun 2021", media: img5 },
    // { id: "media3", label: "Mar 2021", media: img6 },
    // { id: "media4", label: "Feb 2021", media: img7 },
    // { id: "media5", label: "Jan 2021", media: img8 },
    // { id: "media6", label: "Sep 2020", media: img9 },
    // { id: "media7", label: "Aug 2019", media: img10 },
  ];

  const [activeStage, setActiveStage] = useState(stages[0].id);

  const handleNext = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const nextIndex = (currentIndex + 1) % stages.length;
    setActiveStage(stages[nextIndex].id);
  };

  const handlePrev = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const prevIndex = (currentIndex - 1 + stages.length) % stages.length;
    setActiveStage(stages[prevIndex].id);
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  // Tab bar Functionally
  const navRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [navOffset, setNavOffset] = useState(0);

  useEffect(() => {
    if (navRef.current) {
      setNavOffset(navRef.current.offsetTop);
    }

    const handleScroll = () => {
      if (window.pageYOffset >= navOffset) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navOffset]);

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <title>9K Suites Nathia Gali - A Secure & Lifetime Investment</title>
        <meta
          name="description"
          content="9K Suites Nathia Gali is a GDA-approved project, located just 2 km off Kalabagh Road, offers service apartments with a blend of luxuries."
        />
      </Helmet>
      <div className="position-relative view_property">
        <ToastContainer />
        <div
          className=" position-fixed w-100"
          style={{ zIndex: "999999", marginTop: "-8em" }}
        >
          <TopNav />
          <BottomNav />
        </div>
        <div style={{ marginTop: "8em", zIndex: "0" }}>
          <div className="container mt-3 mb-3">
            <div className="row" zIndex="333333">
              <div className="col-sm-8">
                <Slider {...settings}>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={B1}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={B2}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {/* <div>
                  <img
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    src={B3}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  />
                </div> */}
                </Slider>
              </div>
              <div className="col-sm-4">
                <img
                  className="imagedata"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  src={B1}
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                />
                <img
                  className="imagedata"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  src={B2}
                  style={{
                    width: "100%",
                    height: "auto",
                    paddingTop: "0.89em",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container d-flex">
          <a href="#home">
            {" "}
            <i
              class="bi bi-camera mx-2 icon-dark-bg"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
          <i
            class="bi bi-camera-reels mx-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{ cursor: "pointer" }}
          ></i>
          <i
            class="bi bi-geo-alt mx-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{ cursor: "pointer" }}
          ></i>
        </div>

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Arclink
                </h5>

                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Images
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Videos
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Map
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="slider-container p-5">
                      <Slider {...settingss}>
                        <div>
                          <img
                            src={B3}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                        <div>
                          <img
                            src={B2}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="p-5">
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=-i45_7gZbUc"
                        controls={true}
                        width="100%"
                        height="450px"
                      />
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="p-5">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d26598.410096752126!2d73.1189947342085!3d33.55853954654809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfed78f3f8134b%3A0xe42acd9a40a8918f!2sBWC!3m2!1d33.553086!2d73.1366118!5e0!3m2!1sen!2s!4v1721461732000!5m2!1sen!2s"
                        width="1000"
                        height="450"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={navRef}
          className={`mid-nav ${isFixed ? "fixed" : ""}`}
          id="midNav"
          style={{ zIndex: "10000000" }}
        >
          <div className="nav-buttons-container">
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("overview")}
            >
              Overview
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("amenities")}
            >
              Amenities
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("development")}
            >
              Development Updates
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("location")}
            >
              Location
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("payment")}
            >
              Payment Plans Floor Plans
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("marketed")}
            >
              Marketed By
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("developed")}
            >
              Developed By
            </button>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Overview
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />

                    <p
                      style={{
                        paddingTop: "1em",
                      }}
                    >
                      9K Suites Nathia Gali is a GDA-approved project
                      strategically located just 2 km off Kalabagh Road. This
                      service apartment project offers modern and luxurious
                      accommodations that blend seamlessly with the natural
                      beauty of Pakistan's northern landscape.
                    </p>
                    {isExpanded && (
                      <p>
                        The suites of 9K Suites Nathia Gali are designed to
                        provide both tranquility and adventure, ensuring an
                        unforgettable experience for every guest. Each suite at
                        9K Suites Nathia Gali is fully furnished with a keen eye
                        for detail and comfort. The interiors reflect a blend of
                        contemporary elegance and local charm , providing guests
                        with a sophisticated yet cozy atmosphere. Large windows
                        and private terraces offer panoramic views of the
                        surrounding hills, allowing residents to soak in the
                        serene environment from the comfort of their rooms.
                        These luxurious service apartments offer a unique blend
                        of comfort, style, and natural beauty, making them an
                        ideal choice for those seeking a premium living
                        experience in the heart of Nathia Gali.
                      </p>
                    )}
                    <a
                      onClick={toggleReadMore}
                      style={{ color: "green", cursor: "pointer" }}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </a>

                    <div className="row my-2">
                      <div className="list-group">
                        <ol class="list-group list-group">
                          <div
                            class="fw-bold mb-2"
                            style={{ color: "#4db749" }}
                          >
                            Studio Apartment
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Size (Min-Max)</div>
                              290 – 290 (Sq. Ft.)
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Price (Min-Max)</div>
                              18275 – 24482 (PKR)
                            </div>
                            <i class="bi bi-shop"></i>
                          </li>
                          <div
                            class="fw-bold mb-2 mt-2"
                            style={{ color: "#4db749" }}
                          >
                            Delux Apartment
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Size (Min-Max)</div>
                              310 - 310 (Sq. Ft.)
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Price (Min-Max)</div>
                              18387 – 24516 (PKR)
                            </div>
                            <i class="bi bi-shop"></i>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={{
                  backgroundColor: "#3f4040",
                  padding: "2em",
                  borderRadius: "10px",
                }}
              >
                <h5 className="text-center text-white">MAKE AN ENQUIRY</h5>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="name"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control rounded-1"
                          id="sendemail"
                          placeholder=" Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <textarea
                          className="form-control rounded-1"
                          placeholder="Message"
                          id="message"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-primary w-100 py-1">
                        Send
                      </button>
                    </div>

                    <div className="col-md-12 pt-4">
                      <div
                        className="d-flex justify-content-center "
                        style={{ gap: "12px" }}
                      >
                        <a href="#">
                          <button className="btn btn-danger  py-1 px-5">
                            <MdCall color="#fff" /> Call
                          </button>
                        </a>
                        <a href="#">
                          <button className="btn btn-primary  py-1 px-5">
                            <MdEmail color="#fff" /> Email
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <div className="row">
                      <div className="list-group">
                        <ol class="list-group list-group">
                          <div
                            class="fw-bold mb-2 mt-2"
                            style={{ color: "#4db749" }}
                          >
                            Premium Apartment
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Size (Min-Max)</div>
                              468 - 1753
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Price (Min-Max)</div>
                              19619 – 26666 (PKR)
                            </div>
                            <i class="bi bi-shop"></i>
                          </li>
                          <div
                            class="fw-bold mb-2 mt-2"
                            style={{ color: "#4db749" }}
                          >
                            Executive Apartment
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Size (Min-Max)</div>
                              600 – 600 (Sq. Ft.)
                            </div>

                            <div class="ms-2 me-auto">
                              <div class="fw-bold">Price (Min-Max)</div>
                              20000 – 26666 (PKR)
                            </div>
                            <i class="bi bi-shop"></i>
                          </li>
                        </ol>
                        <ol class="list-group list-group">
                          <li class="list-group-item d-flex justify-content-between align-items-start mt-4">
                            <div class="ms-2 me-auto">
                              <i class="bi bi-geo-alt"></i>
                              <div class="fw-bold">
                                9K Suites
                                <br /> Nathia Gali.
                              </div>
                              Location
                            </div>
                            <div class="ms-2 me-auto">
                              <i class="bi bi-person"></i>
                              <div class="fw-bold">CRISTALLO. </div>
                              Developer's
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="amenities">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Amenities
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row">
                      <div className="list-group">
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/terrace.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Private terraces with every unit
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/lobby.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">A welcoming lobby</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/restaurant.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Gourmet restaurant</div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/lounge.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Exclusive executive lounge
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/playarea.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                {" "}
                                Play area for children
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/game.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold"> Game room</div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/gym.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Fully-equipped gym</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/cinema.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Mini cinema</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/business-center.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Business center</div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/hall.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Multi-purpose hall</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/mosque.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Prayer area</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/conference-room.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Conference room</div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/commonarea.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Elegant common areas
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/coveredparking.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">Covered parking</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/rooftop.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Rooftop entertainment
                              </div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/security.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">24/7 security</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/wifi.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                High-speed WIFI connectivity
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/power-backup.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Reliable power backup
                              </div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/lightning.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Advanced lightning protection
                              </div>
                            </div>
                            {/* <div className="ms-2 me-auto">
                              <img
                                src={require("../img/balcony.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                High-speed WIFI connectivity
                              </div>
                            </div> */}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="project-specification">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Project Specification
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row">
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../img/business-center.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">
                              Fully Furnished Apartments
                            </div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../img/hall.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Guaranteed Rentals</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../img/kitchen1.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">
                              Upto 3 weeks complimentary stay
                            </div>
                          </div>
                        </li>
                      </ol>
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../img/snowarea.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Full Snow Area</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../img/terrace.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">360 Panoramic Views</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-p-circle-fill"></i>
                            <div className="fw-bold">Buy Back Policy</div>
                          </div>
                        </li>
                      </ol>
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../img/rooftop.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Roomy Hotel</div>
                          </div>
                          {/* <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-plug"></i>
                            <div className="fw-bold">Power backup</div>
                          </div> */}
                          <div className="col-sm-4  ms-2 me-auto"></div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="development">
          <div className="row">
            <div className="col-12">
              <div className="read-more-container">
                <h3 style={{ color: "#4db749", fontWeight: "bold" }}>
                  Development Updates
                </h3>
                <img src={require("../img/arrow.png")} alt="" />
                <div className="row">
                  <div className="col-12">
                    <div className="timeline">
                      {stages.map((stage, index) => (
                        <div key={stage.id} className="timeline-stage">
                          <div
                            className={`circle ${
                              activeStage === stage.id ? "active" : ""
                            }`}
                            onClick={() => setActiveStage(stage.id)}
                          >
                            {stage.label}
                          </div>
                          {index < stages.length - 1 && (
                            <div className="line"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <button onClick={handlePrev} className="arrow-btn">
                      ←
                    </button>
                    <div className="media-container">
                      {stages.map((stage) => (
                        <div
                          key={stage.id}
                          className="media-content-wrapper"
                          style={{
                            display:
                              activeStage === stage.id ? "block" : "none",
                          }}
                        >
                          <div className="media-label">{stage.label}</div>
                          <img
                            id={stage.id}
                            src={stage.media}
                            alt={stage.label}
                            className=" media-content"
                          />
                        </div>
                      ))}
                    </div>
                    <button onClick={handleNext} className="arrow-btn">
                      →
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="location">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Location
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row mt-3">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d848360.0750343994!2d72.35523694221354!3d33.840520708151516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x38df948974419acb%3A0x984357e1632d30f!2sRawalpindi!3m2!1d33.5651107!2d73.0169135!4m5!1s0x38dfd4b203243f2b%3A0xe2a84441ca2e0678!2sNathia%20Gali%20Abbottabad%2C%20Khyber%20Pakhtunkhwa!3m2!1d34.0729404!2d73.3811849!5e0!3m2!1sen!2s!4v1728371403404!5m2!1sen!2s"
                        width="600"
                        height="450"
                        style={{ borderRadius: "20px" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="payment">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Payment And Floor plans
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />

                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4">
                        <div className="card" style={{ width: "100%" }}>
                          <div className="row">
                            <a
                              href={paymentplan}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={karat1}
                                className="card-img-top"
                                alt="..."
                                style={{ padding: "10px", borderRadius: "5px" }}
                              />
                            </a>
                            <div className="card-body">
                              <p className="card-text">
                                9K Suites Nathia Gali Payment Plan
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4">
                        <div className="card" style={{ width: "100%" }}>
                          <a
                            href={floorplan}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={karat2}
                              className="card-img-top"
                              alt="..."
                              style={{ padding: "10px", borderRadius: "5px" }}
                            />
                          </a>
                          <div className="card-body">
                            <p className="card-text">
                              9K Suites Nathia Gali Floor Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mt-4">
                      <a
                        href={paymentplan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div class="card" style={{ width: "18rem" }}>
                          <img
                            src={karat1}
                            class="card-img-top"
                            alt="..."
                            style={{ padding: "10px", borderRadius: "5px" }}
                          />
                          <div class="card-body">
                            <p class="card-text">9K Suites Nathia Gali </p>
                          </div>
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-3 mt-3">
          <div className="row">
            <div className="col-md-6 mb-3" id="marketed">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={makkaanLogo}
                      className="img-fluid rounded-start"
                      alt="..."
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Marketed By</h3>
                      <h5 className="card-title">Makkaan.com</h5>
                      <p>
                        Makkaan.com is a trustworthy and responsible platform
                        that aims to provide you with all the Residential,
                        Commercial, and Investment details under the same roof.
                      </p>
                      {isExpanded && (
                        <p>
                          The only podium that offers reliable services to
                          investors, dealers, societies, and end-users of the
                          real estate world throughout the country. We use an
                          all-inclusive marketing strategy that covers all
                          aspects of projects and helps investors at every stage
                          of the process with complete transparency.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3" id="developed">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={developerlogo}
                      className="img-fluid rounded-start"
                      alt="..."
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Developed By</h3>
                      <h5 className="card-title">9K Suites</h5>
                      <p>
                        Mr. Muqtadir Qazi is the developer of 9k Suites Nathia
                        Gali. With several years of experience in Real Estate,
                        he is a prominent name for 9k suites in Nathia Gali.
                      </p>
                      {isExpanded && (
                        <p>
                          After the delivery of the successful project in Ayubia
                          - 9K Suites Ayubia- he now aims to provide all the
                          basic and modern features and amenities in this
                          project in Nathia Gali. So, this is one of the
                          specific projects that gives you to earn from your
                          residence.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Nathiagali;
