import React, { useState } from "react";

import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import Footer from "../components/footer/Footer";
import { ToastContainer } from "react-toastify";
import { MdCall, MdEmail } from "react-icons/md";
import Akbarvista1 from "../makkaanpages/images/av13.webp";
import AL2 from "../makkaanpages/images/legallyapproved.jpg";
import AL1 from "../makkaanpages/images/arclink.jpg";
import AL3 from "../makkaanpages/images/ALnearbylandmark.jpg";
import B1 from "../makkaanpages/images/BWC1.png";
import B2 from "../makkaanpages/images/BWC2.png";
import B3 from "../makkaanpages/images/BWC3.png";
import img5 from "../makkaanpages/images/av5.webp";
import img6 from "../makkaanpages/images/av6.webp";
import img7 from "../makkaanpages/images/av7.webp";
import img8 from "../makkaanpages/images/av8.webp";
import img9 from "../makkaanpages/images/av9.webp";
import img10 from "../makkaanpages/images/av10.webp";

import karat1 from "../makkaanpages/images/24karat.jpeg";
import developerlogo from "../makkaanpages/images/ARCLINK-SITE-LOGO.webp";
import makkaanLogo from "../makkaanpages/images/makkaanlogo.png";

import paymentplan from "../makkaanpages/pdf/24-Karat.pdf";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import "./CustomArrow.css";
import "../App.css";

import { SampleNextArrow, SamplePrevArrow } from "./CustomArrow";

function Blueworldcity() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const stages = [
    { id: "media1", label: "Aug 2021", media: B1 },
    { id: "media2", label: "Jun 2021", media: img5 },
    { id: "media3", label: "Mar 2021", media: img6 },
    { id: "media4", label: "Feb 2021", media: img7 },
    { id: "media5", label: "Jan 2021", media: img8 },
    { id: "media6", label: "Sep 2020", media: img9 },
    { id: "media7", label: "Aug 2019", media: img10 },
  ];

  const [activeStage, setActiveStage] = useState(stages[0].id);

  const handleNext = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const nextIndex = (currentIndex + 1) % stages.length;
    setActiveStage(stages[nextIndex].id);
  };

  const handlePrev = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const prevIndex = (currentIndex - 1 + stages.length) % stages.length;
    setActiveStage(stages[prevIndex].id);
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <div className="position-relative view_property">
        <ToastContainer />
        <div className="w-100" style={{ zIndex: "999999" }}>
          <TopNav />
          <BottomNav />
        </div>

        <div className="container mt-3 mb-3">
          <div className="row">
            <div className="col-sm-8">
              <Slider {...settings}>
                <div>
                  <img
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    src={B1}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  />
                </div>
                <div>
                  <img
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    src={B2}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  />
                </div>
                <div>
                  <img
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    src={B3}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  />
                </div>
              </Slider>
            </div>
            <div className="col-sm-4">
              <img
                className="imagedata"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                src={B3}
                style={{ width: "100%", height: "auto", cursor: "pointer" }}
              />
              <img
                className="imagedata"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                src={B2}
                style={{
                  width: "100%",
                  height: "auto",
                  paddingTop: "0.89em",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        <div class="container d-flex">
          <a href="#home">
            {" "}
            <i
              class="bi bi-camera mx-2 icon-dark-bg"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
          <i
            class="bi bi-camera-reels mx-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{ cursor: "pointer" }}
          ></i>
          <i
            class="bi bi-geo-alt mx-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{ cursor: "pointer" }}
          ></i>
        </div>

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Arclink
                </h5>

                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Images
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Videos
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Map
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="slider-container p-5">
                      <Slider {...settingss}>
                        <div>
                          <img
                            src={B3}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                        <div>
                          <img
                            src={B2}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="p-5">
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=-i45_7gZbUc"
                        controls={true}
                        width="100%"
                        height="450px"
                      />
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="p-5">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d26598.410096752126!2d73.1189947342085!3d33.55853954654809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfed78f3f8134b%3A0xe42acd9a40a8918f!2sBWC!3m2!1d33.553086!2d73.1366118!5e0!3m2!1sen!2s!4v1721461732000!5m2!1sen!2s"
                        width="1000"
                        height="450"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Overview
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />

                    <p
                      style={{
                        paddingTop: "1em",
                      }}
                    >
                      24 Karat Crown is nestled at the hub of Rawalpindi and
                      Islamabad and offers residential and commercial under the
                      same roof. The location of the 24 Karat Crown defines its
                      prominence.
                    </p>

                    <ol class="list-group list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-start mt-4">
                        <div class="ms-2 me-auto">
                          <i class="bi bi-geo-alt"></i>
                          <div class="fw-bold">
                            Block C,
                            <br /> Phase 8 Bahria Town Islamabad.
                          </div>
                          Location
                        </div>
                        <div class="ms-2 me-auto">
                          <i class="bi bi-person"></i>
                          <div class="fw-bold">CRISTALLO. </div>
                          Developer's
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={{
                  backgroundColor: "#3f4040",
                  padding: "2em",
                  borderRadius: "10px",
                }}
              >
                <h5 className="text-center text-white">MAKE AN ENQUIRY</h5>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="name"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control rounded-1"
                          id="sendemail"
                          placeholder=" Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <textarea
                          className="form-control rounded-1"
                          placeholder="Message"
                          id="message"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-primary w-100 py-1">
                        Send
                      </button>
                    </div>

                    <div className="col-md-12 pt-4">
                      <div
                        className="d-flex justify-content-center "
                        style={{ gap: "12px" }}
                      >
                        <a href="#">
                          <button className="btn btn-danger  py-1 px-5">
                            <MdCall color="#fff" /> Call
                          </button>
                        </a>
                        <a href="#">
                          <button className="btn btn-primary  py-1 px-5">
                            <MdEmail color="#fff" /> Email
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Size & Prices
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row">
                      <div className="list-group">
                        <ol class="list-group list-group">
                          <div
                            class="fw-bold mb-2"
                            style={{ color: "#4db749" }}
                          >
                            Shops
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Size (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              585 - 250
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Price (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              22000 - 55000
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Size (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              8,750,000 -26,125,000
                            </div>

                            <i class="bi bi-shop"></i>
                          </li>
                          <div
                            class="fw-bold mb-2 mt-2"
                            style={{ color: "#4db749" }}
                          >
                            Offices
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Size (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              465 - 840
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Price (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              25000
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Total Price (PKR)
                                <br />
                                (min–max){" "}
                              </div>
                              11,625,000 - 21,000,000
                            </div>
                            <i class="bi bi-shop"></i>
                          </li>
                          <div
                            class="fw-bold mb-2 mt-2"
                            style={{ color: "#4db749" }}
                          >
                            Apartments
                          </div>
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Size (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              468 - 1753
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Price (Sq.Ft)
                                <br /> (min-max){" "}
                              </div>
                              15000
                            </div>
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">
                                Total Price (PKR)
                                <br />
                                (min–max){" "}
                              </div>
                              7,020,000 - 26,295,000
                            </div>
                            <i class="bi bi-shop"></i>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Amenties
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row">
                      <div className="list-group">
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/balcony.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Educational Institutions
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/balcony.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Healthcare facilities
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <img
                                src={require("../img/balcony.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <div className="fw-bold">
                                Recreational Centers
                              </div>
                            </div>
                          </li>
                        </ol>
                        <ol className="list-group list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                            <div className="ms-2 me-auto">
                              {/* <img
                              src={require("../img/gym.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            /> */}
                              <div className="fw-bold">
                                Health and <br></br> Gym Centers
                              </div>
                            </div>
                            <div className="ms-2 me-auto">
                              <i class="bi bi-signpost"></i>
                              <div className="fw-bold">Recreational Parks</div>
                            </div>
                            <div className="ms-2 me-auto">
                              <i class="bi bi-shop-window"></i>
                              <div className="fw-bold">Commercial Hubs</div>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <div className="read-more-container">
                <h3 style={{ color: "#4db749", fontWeight: "bold" }}>
                  Development Updates
                </h3>
                <img src={require("../img/arrow.png")} alt="" />
                <div className="row">
                  <div className="col-12">
                    <div className="timeline">
                      {stages.map((stage, index) => (
                        <div key={stage.id} className="timeline-stage">
                          <div
                            className={`circle ${
                              activeStage === stage.id ? "active" : ""
                            }`}
                            onClick={() => setActiveStage(stage.id)}
                          >
                            {stage.label}
                          </div>
                          {index < stages.length - 1 && (
                            <div className="line"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <button onClick={handlePrev} className="arrow-btn">
                      ←
                    </button>
                    <div className="media-container">
                      {stages.map((stage) => (
                        <div
                          key={stage.id}
                          className="media-content-wrapper"
                          style={{
                            display:
                              activeStage === stage.id ? "block" : "none",
                          }}
                        >
                          <div className="media-label">{stage.label}</div>
                          <img
                            id={stage.id}
                            src={stage.media}
                            alt={stage.label}
                            className=" media-content"
                          />
                        </div>
                      ))}
                    </div>
                    <button onClick={handleNext} className="arrow-btn">
                      →
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Location
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row mt-3">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d26598.410096752126!2d73.1189947342085!3d33.55853954654809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfed78f3f8134b%3A0xe42acd9a40a8918f!2sBWC!3m2!1d33.553086!2d73.1366118!5e0!3m2!1sen!2s!4v1721461732000!5m2!1sen!2s"
                        width="1000"
                        height="450"
                        style={{ borderRadius: "20px" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Payment And Floor plans
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row mt-4">
                      <a
                        href={paymentplan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div class="card" style={{ width: "18rem" }}>
                          <img
                            src={karat1}
                            class="card-img-top"
                            alt="..."
                            style={{ padding: "10px", borderRadius: "5px" }}
                          />
                          <div class="card-body">
                            <p class="card-text">Blue World City</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-3 mt-3">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={makkaanLogo}
                      className="img-fluid rounded-start"
                      alt="..."
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Marketed By</h3>
                      <h5 className="card-title">Makkaan.com</h5>
                      <p>
                        Makkaan.com is a trustworthy and responsible platform
                        that aims to provide you with all the Residential,
                        Commercial, and Investment details under the same roof.
                      </p>
                      {isExpanded && (
                        <p>
                          The only podium that offers reliable services to
                          investors, dealers, societies, and end-users of the
                          real estate world throughout the country. We use an
                          all-inclusive marketing strategy that covers all
                          aspects of projects and helps investors at every stage
                          of the process with complete transparency.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={developerlogo}
                      className="img-fluid rounded-start"
                      alt="..."
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Developed By</h3>
                      <h5 className="card-title">CRISTALLO</h5>
                      <p>
                        This project’s developer is Stronghold Builders who are
                        known for their three-word approach: ‘Customer Comes
                        First.’ Stronghold Builders was founded by Mr. Ghulam
                        Akbar Khan.
                      </p>
                      {isExpanded && (
                        <p>
                          They have been here since 2000 when they launched as a
                          general construction company, and now, after all these
                          years and more than two decades, they have made their
                          name among the top trustworthy developers in the
                          Islamabad and Karachi region of Pakistan. Stronghold
                          Builders confidently provides top-notch services in
                          demolition, construction, and renovation, with a
                          proven track record of delivering successful projects
                          while prioritizing their clients’ needs above all
                          else.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Blueworldcity;
