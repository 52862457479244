import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import api_url from "../../ApiUrl";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();

  const [signAs, setSignAs] = useState("");
  const [agencyLogoss, setAgencyLogo] = useState([]);

  const [myImage, setMyImage] = useState([]);
  useEffect(() => {
    if (myImage) {
      console.log(myImage);
    }
  }, [myImage]);
  const submit = async () => {
    const fullName = document.getElementById("fullName");
    const phone = document.getElementById("phone");
    const city = document.getElementById("city");
    const email = document.getElementById("emails");
    const password = document.getElementById("passwords");
    const cpassword = document.getElementById("cpasswords");
    const agencyName = document.getElementById("agencyName");
    const serviceArea = document.getElementById("serviceArea");
    const agencyDescription = document.getElementById("agencyDescription");

    if (fullName.value.length === 0) {
      toast.error("Must enter name");
    } else if (phone.value.length === 0) {
      toast.error("Must enter phone");
    } else if (email.value.length === 0) {
      toast.error("Must enter email");
    } else if (password.value.length === 0) {
      toast.error("Must enter password");
    } else if (cpassword.value.length === 0) {
      toast.error("Must enter confirm password");
    } else if (password.value !== cpassword.value) {
      toast.error("Password and confirm password must be same");
    } else if (city.value.length === 0) {
      toast.error("Must enter city");
    } else {
      let imageUrl = "";

      if (agencyLogoss.length > 0) {
        try {
          let param = new FormData();
          param.append("avatars", agencyLogoss[0]);
          const profilephoto = await axios.post(`${api_url}blog/image`, param);
          imageUrl = profilephoto?.data?.data[0]?.url || "";
          setMyImage(imageUrl);
        } catch (error) {
          console.log(error);
        }
      }

      const params = {
        city: city.value,
        fullName: fullName.value,
        phone: phone.value,
        email: email.value,
        password: password.value,
        signAs: signAs,
        agencyName: agencyName ? agencyName.value : null,
        serviceArea: serviceArea ? serviceArea.value : null,
        agencyDescription: agencyDescription ? agencyDescription.value : null,
        agencyLogo: imageUrl || null,
      };

      try {
        const res = await axios.post(`${api_url}user/create`, params);
        if (res.data.status === "ok") {
          document.getElementById("gologin").click();
          toast.success("Register successfully!");
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <TopNav />
      <BottomNav />
      <section className="Signup_card">
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <Swiper
                spaceBetween={60}
                navigation
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper overflow-hidden"
              >
                <SwiperSlide>
                  <div className="">
                    <img
                      src={require("../../assets/images/ad property image.jpg")}
                      width="100%"
                      alt=""
                    />
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                                    <div className="">
                                        <img src={require('../../assets/images/ad property image.jpg')} width="100%" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="">
                                        <img src={require('../../assets/images/ad property image.jpg')} width="100%" alt="" />
                                    </div>
                                </SwiperSlide> */}
              </Swiper>
            </div>
            <div className="col-md-5 pt-5">
              <div className="position-relative formimg">
                <div className="">
                  <img src={require("../../1.png")} />
                </div>
                <div className="row card_row position-absolute top-0 w-100">
                  <div className="col-md-11 mx-auto">
                    <div className="">
                      <div className="mx-3">
                        <h4 className="">Sign Up</h4>
                      </div>

                      <div className="rounded-4 shadow-lg p-4 bg-white">
                        <form>
                          <div className="mb-2">
                            <label
                              htmlFor="exampleInputName"
                              className="form-label"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                              id="fullName"
                              aria-describedby="emailHelp"
                              placeholder="Write Full Name"
                            />
                          </div>
                          <div className="pt-2">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                              id="emails"
                              aria-describedby="emailHelp"
                              placeholder="abc@xyz.com"
                            />
                          </div>
                          <div className="pt-2">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                              id="passwords"
                              aria-describedby="emailHelp"
                              placeholder="Create a strong password"
                            />
                          </div>
                          <div className="pt-2">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                              id="cpasswords"
                              aria-describedby="emailHelp"
                              placeholder="Confirm your password"
                            />
                          </div>
                          <div className="pt-2">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                            <input
                              type="Number"
                              className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                              id="phone"
                              aria-describedby="emailHelp"
                              placeholder="+923-0000000"
                            />
                          </div>
                          <div className="pt-2">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label m-0"
                            >
                              City
                            </label>
                            <select
                              class="form-select form-select-md m-0 border-0 rounded-0 p-0 border-bottom"
                              aria-label=".form-select-md example"
                              name="city"
                              id="city"
                              required
                            >
                              <option value="" disabled selected>
                                Select The City
                              </option>
                              <option value="Islamabad">Islamabad</option>
                              <option value="Rawalpindi">Rawalpindi</option>
                            </select>
                          </div>

                          <div className="pt-2">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Signing up as
                            </label>
                            <div className="form-check d-flex justify-content-between align-items-center">
                              {/* <div onClick={() => setSignAs('buyer')}>
                                                                <input type="radio" className="form-check-input" id="buyer" name='signmeas' />
                                                                <label className="form-check-label" htmlFor="buyer">
                                                                    Buyer
                                                                </label>
                                                            </div> */}
                              <div onClick={() => setSignAs("seller")}>
                                <input
                                  type="radio"
                                  name="signmeas"
                                  className="form-check-input"
                                  id="seller"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="seller"
                                >
                                  Seller
                                </label>
                              </div>
                              <div onClick={() => setSignAs("agency")}>
                                <input
                                  type="radio"
                                  name="signmeas"
                                  className="form-check-input"
                                  id="agency"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="agency"
                                >
                                  Agency
                                </label>
                              </div>
                              <div onClick={() => setSignAs("developer")}>
                                <input
                                  type="radio"
                                  name="signmeas"
                                  className="form-check-input"
                                  id="developer"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="developer"
                                >
                                  Developer
                                </label>
                              </div>
                            </div>
                          </div>

                          {signAs === "developer" ? (
                            <div style={{ zIndex: "999999" }}>
                              <div className="pt-2">
                                <label
                                  htmlFor="exampleInputagencyname"
                                  className="form-label"
                                >
                                  Developer Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                                  id="agencyName"
                                  aria-describedby="agencyHelp"
                                  placeholder="Enter Developer Name"
                                />
                              </div>
                              <div className="pt-2">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label m-0"
                                >
                                  Service Area
                                </label>
                                <select
                                  class="form-select form-select-md m-0 border-0 rounded-0 p-0 border-bottom"
                                  aria-label=".form-select-md example"
                                  name="serviceArea"
                                  id="serviceArea"
                                  required
                                >
                                  <option value="" disabled selected>
                                    Select The service area
                                  </option>
                                  <option value="Islamabad">Islamabad</option>
                                  <option value="Rawalpindi">Rawalpindi</option>
                                </select>
                              </div>

                              <div class="pt-2" style={{ zIndex: "999999" }}>
                                <label
                                  htmlFor="exampleInputagencyname"
                                  className="form-label"
                                >
                                  Developer Description
                                </label>
                                <div class="form-floating">
                                  <textarea
                                    class="form-control location_btn"
                                    rows="8"
                                    placeholder="Leave a comment here"
                                    id="agencyDescription"
                                    style={{
                                      height: "150px",
                                      zIndex: "999999",
                                    }}
                                    name="agencyDescription"
                                  ></textarea>
                                </div>
                              </div>

                              <div className="pt-2">
                                <label
                                  htmlFor="exampleInputagencyname"
                                  className="form-label"
                                >
                                  Developer Logo
                                </label>
                                <input
                                  type="file"
                                  className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                                  id="agencyLogo"
                                  name="agencyLogo"
                                  aria-describedby="agencyHelp"
                                  placeholder="Enter logo"
                                  onChange={(e) =>
                                    setAgencyLogo(e.target.files)
                                  }
                                />
                              </div>
                            </div>
                          ) : null}

                          {signAs === "agency" ? (
                            <div style={{ zIndex: "999999" }}>
                              <div className="pt-2">
                                <label
                                  htmlFor="exampleInputagencyname"
                                  className="form-label"
                                >
                                  Agency Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                                  id="agencyName"
                                  aria-describedby="agencyHelp"
                                  placeholder="Enter Agency Name"
                                />
                              </div>
                              <div className="pt-2">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label m-0"
                                >
                                  Service Area
                                </label>
                                <select
                                  class="form-select form-select-md m-0 border-0 rounded-0 p-0 border-bottom"
                                  aria-label=".form-select-md example"
                                  name="serviceArea"
                                  id="serviceArea"
                                  required
                                >
                                  <option value="" disabled selected>
                                    Select The service area
                                  </option>
                                  <option value="Islamabad">Islamabad</option>
                                  <option value="Rawalpindi">Rawalpindi</option>
                                </select>
                              </div>

                              <div class="pt-2" style={{ zIndex: "999999" }}>
                                <label
                                  htmlFor="exampleInputagencyname"
                                  className="form-label"
                                >
                                  Agency Description
                                </label>
                                <div class="form-floating">
                                  <textarea
                                    class="form-control location_btn"
                                    rows="8"
                                    placeholder="Leave a comment here"
                                    id="agencyDescription"
                                    style={{ height: "150px" }}
                                    name="agencyDescription"
                                  ></textarea>
                                </div>
                              </div>

                              <div className="pt-2">
                                <label
                                  htmlFor="exampleInputagencyname"
                                  className="form-label"
                                >
                                  Agency Logo
                                </label>
                                <input
                                  type="file"
                                  className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                                  id="agencyLogo"
                                  name="agencyLogo"
                                  aria-describedby="agencyHelp"
                                  placeholder="Titanium"
                                  onChange={(e) =>
                                    setAgencyLogo(e.target.files)
                                  }
                                />
                              </div>
                            </div>
                          ) : null}

                          <div className="text-end">
                            <button
                              type="button"
                              className="btn px-4 mt-2 text-white"
                              style={{ backgroundColor: "#4DB749" }}
                              onClick={submit}
                            >
                              Sign Up
                            </button>
                            <button
                              id="gologin"
                              type="button"
                              className="btn"
                              style={{ opacity: "0" }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              Any
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Signup;
