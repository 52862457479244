import React, { useEffect, useState } from "react";
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api_url from "../../ApiUrl";

const DeveloperList = () => {
  const [myPost, setMyPost] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(api_url + `user/get`).then((res) => {
      const allUsers = res.data.data;
      const developerUsers = allUsers.filter(
        (user) => user.signAs === "developer"
      );
      console.log(res.data, "==================>>>>>>");
      setMyPost(developerUsers);
      console.log(developerUsers);
    });
  }, []);

  return (
    <>
      <div className="position-relative">
        <div className="position-fixed w-100" style={{ zIndex: "999999" }}>
          <TopNav />
          <BottomNav />
        </div>
        <br />
        <br />
        <br />
        <br />
        <div
          id="intro-example"
          className="text-center bg-image"
          style={{
            backgroundImage: `url('${require("./../../img/banner/bg_rectangle.png")}')`,
          }}
        >
          <div className="container">
            <br />
            <center>
              <h3
                className="text-white"
                style={{
                  fontWeight: "700",
                  fontSize: "36px",
                  lineHeight: "43.2px",
                }}
              >
                Developers
              </h3>
            </center>
            <div className="card p-2">
              <div className="row p-2">
                <div className="col-md-5 mx-auto">
                  <input
                    className="form-control py-2"
                    type="text"
                    placeholder="Keyword"
                    id="title"
                    style={{ height: "50px", borderRadius: "4px" }}
                  />
                </div>
                <div className="col-md-5 mx-auto">
                  <div>
                    <select
                      className="form-select form-select-md mb-3 py-2"
                      aria-label=".form-select-md example"
                      name="city"
                      id="city"
                      style={{ height: "50px", borderRadius: "4px" }}
                      required
                    >
                      <option value="" disabled>
                        Select The City
                      </option>
                      <option value="Islamabad" selected>
                        Islamabad
                      </option>
                      <option value="Rawalpindi" disabled>
                        Rawalpindi
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-1 mx-auto">
                  <button
                    type="button"
                    className="btn btn-light bg_makkan text-white btn-lg mx-auto"
                    style={{ backgroundColor: "#4DB749" }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {myPost.filter((i) => i.signAs === "developer" && i.featured === false)
          .length > 0 ? (
          <div className="container">
            <br />
            <div className="col-md-12">
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "28.8px",
                }}
              >
                {myPost.length} &nbsp;
                <span className="bg_color_maken">Developers</span> in Islamabad
              </h1>
            </div>
            {myPost.length > 0
              ? myPost.map((i) => {
                  if (i?.signAs === "developer" && i.featured === false) {
                    return (
                      <>
                        <Link to={`/developerdetails/${i?.id}`}>
                          <div className="row">
                            <div className="col-md-10">
                              <div className="row">
                                <br />
                                <br />

                                <br />

                                <div
                                  className="col-md-12"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="card mb-3 p-3">
                                    <div className="row p-2">
                                      <div className="col-md-2">
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "120px",
                                            backgroundImage: `url(${i?.agencyLogo})`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                          }}
                                          className="img-fluid rounded-start"
                                        ></div>
                                      </div>
                                      <div className="col-md-7">
                                        <Link
                                          className="nav-link bg_color_maken"
                                          to={`/agenciesdetails/${i?.id}`}
                                          style={{
                                            color: "#4DB749",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            lineHeight: "24px",
                                          }}
                                        >
                                          {i.fullName}
                                        </Link>
                                        <span
                                          className="text-muted"
                                          style={{
                                            fontWeight: "700",
                                            fontSize: "20px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          {i?.agencyName}
                                        </span>
                                        <p
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          <span
                                            className="text-muted"
                                            style={{
                                              fontWeight: "700",
                                              fontSize: "13px",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <img
                                              src={require("./../../img/agencey/location_icon.jpg")}
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                            {i?.city}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-md-3">
                                        <br />
                                        <br />

                                        <a
                                          href=""
                                          className="btn bg_makkan text-white"
                                          style={{
                                            backgroundColor: "#4DB749",
                                            marginRight: "5px",
                                          }}
                                        >
                                          <i className="fas fa-phone-flip"></i>{" "}
                                          Call
                                        </a>
                                        <a
                                          href=""
                                          className="btn btn-outline-success"
                                        >
                                          <i className="far fa-envelope"></i>{" "}
                                          Mail
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <br />
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  }
                })
              : null}
          </div>
        ) : null}

        <div className="container m-4">
          <div className="row">
            <div className="col-md-12">
              <a href="">
                <img
                  src={require("./../../img/ads/Group 10099.png")}
                  style={{ width: "100%", height: "200px" }}
                />
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default DeveloperList;
