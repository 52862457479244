import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";
import HTMLReactParser from "html-react-parser";
import api_url from "../../ApiUrl";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

const ViewBlogs = () => {
  const { id, name } = useParams();

  const [myPost, setMyPost] = useState({});
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    axios.get(`${api_url}blog/get/${name}`).then((res) => {
      setMyPost(res.data?.data);
    });

    // Fetch latest posts
    axios.get(api_url + `blog/get`).then((res) => {
      // console.log("fetching Latest Data:", res.data?.data);
      setLatestPosts(res.data?.data || []);
    });
  }, [id]);

  // Slice the latest posts to show only the first 4
  const latestFourPosts = [...latestPosts].reverse().slice(0, 3);

  const formatName = (name) => {
    return name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "");
  };

  return (
    <>
      <TopNav />
      <BottomNav />

      <Helmet>
        <title>
          {(myPost?.name &&
            myPost.name
              .replace(/-/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())) ||
            "Default Blog Title"}
        </title>
        <meta name="description" content={myPost?.meta} />
      </Helmet>

      <div
        id="intro-example"
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: `url('${require("./../../img/banner/Group 22 (6).png")}')`,
          height: "170px",
        }}
      ></div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <img
                  style={{
                    width: "100%",
                    height: "400px",

                    marginBottom: "4em",
                  }}
                  src={myPost?.image}
                  className="img-fluid w-100"
                  alt="no photo"
                />

                <h1
                  style={{
                    fontWeight: "700",
                    fontSize: "35px",
                    lineHeight: "33.6px",
                  }}
                >
                  {
                    myPost?.name &&
                      myPost.name
                        .replace(/-/g, " ") // Replace hyphens with spaces
                        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                  }
                </h1>
                <ul
                  style={{ listStyle: "none", paddingLeft: "0px" }}
                  className="nav"
                >
                  <li>
                    <img
                      src={require("./../../img/house-2.png")}
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "10.51px",
                        marginLeft: "10px",
                      }}
                    >
                      {" "}
                      Makkaan
                    </span>
                  </li>
                  <li style={{ marginLeft: "10px" }}>
                    <img
                      src={require("./../../img/calender.png")}
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "10.51px",
                        marginLeft: "10px",
                        marginBottom: "2em",
                      }}
                    >
                      {new Date(myPost.createdAt).toLocaleDateString()}
                    </span>
                  </li>
                </ul>

                {typeof myPost?.description === "string" && (
                  <p style={{ marginTop: "4em" }}>
                    {HTMLReactParser(myPost?.description)}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-3">
              <h3>
                <span
                  className="text-white bg_makkans"
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    lineHeight: "29.4px",
                  }}
                >
                  Latest{" "}
                </span>
                Post
              </h3>
              <div className="row">
                {latestFourPosts.map((post) => (
                  <div
                    key={post.id}
                    className="col-md-12"
                    style={{ padding: "5px", cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href = `/blog/${formatName(post.name)}`)
                    }
                  >
                    <div className="card mb-3">
                      <div className="row g-0">
                        <div className="col-md-4">
                          <img
                            style={{ width: "100%", height: "130px" }}
                            src={post.image}
                            alt={post.name}
                            className="img-fluid rounded-start"
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                fontWeight: "500",
                                fontSize: "17px",
                                lineHeight: "25.5px",
                              }}
                            >
                              {post?.name &&
                                post.name
                                  .replace(/-/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                            </h5>
                            <ul
                              style={{ listStyle: "none", paddingLeft: "0px" }}
                              className="nav"
                            >
                              <li>
                                <img
                                  src={require("./../../img/house-2.png")}
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "10.51px",
                                    lineHeight: "10.51px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {" "}
                                  Makkan
                                </span>{" "}
                                |
                              </li>
                              <li style={{ marginLeft: "10px" }}>
                                <img
                                  src={require("./../../img/calender.png")}
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "10.51px",
                                    lineHeight: "10.51px",
                                  }}
                                >
                                  {" "}
                                  {new Date(
                                    post.createdAt
                                  ).toLocaleDateString()}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ViewBlogs;
